<template>
	<v-card class="task pa-3" ref="task" @click="navigate(task.ctaLink)">
		<v-row class="ma-0" align-content="start">
			<v-icon small color="white">{{ task.icon }}</v-icon>
			<p style="color: white" class="mb-0 ml-3 task-title">
				{{ task.name }}
			</p>
		</v-row>
		<p style="color: white" class="mt-2 mb-1">{{ task.description }}</p>
		<v-row class="ma-0" align-content="end">
			<v-spacer></v-spacer>
			<p style="color: white" class="mb-0 task-title">
				{{ task.cta }}
			</p>
			<v-icon small color="white" class="ml-3" :size="16"
				>fas fa-chevron-right</v-icon
			>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: 'task',
	props: ['checked', 'label', 'task', 'disabled'],
	data() {
		return {
			open: false,
			dismissed: false
		}
	},
	computed: {
		descriptionWidth() {
			if (this.$refs.taskBody) {
				return `${this.$refs.taskBody.clientWidth}px`
			}
			return 'auto'
		}
	},
	methods: {
		dismissTask() {
			this.dismissed = true
			let dismissedList = localStorage.dismissedList
			if (dismissedList) {
				dismissedList = JSON.parse(dismissedList)
				dismissedList.push(this.task.id)
			} else {
				dismissedList = [this.task.id]
			}
			localStorage.dismissedList = JSON.stringify(dismissedList)
		},
		navgiateTaskLink() {
			if (window.analytics) {
				window.analytics.track('nonprofit_todo', { ...this.task })
			}
			this.navigate(this.task.ctaLink)
		}
	}
}
</script>

<style lang="scss">
.task {
	min-height: 87px !important;
	width: 100% !important;
	margin: 5px !important;
	margin-left: 0px !important;
	margin-right: 15px !important;
	background: $roundup-purple !important;
	margin-bottom: 15px !important;
	&:hover {
		cursor: pointer;
	}
	p {
		color: white !important;
	}
	.task-title {
		font-size: 0.875rem !important;
		font-weight: $font-medium !important;
	}
}
</style>
