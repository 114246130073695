<template>
	<v-container class="footer-row">
		<v-img
			src="@/assets/roundup_logo.svg"
			style="margin-bottom: 10px !important"
		/>
		<p class="mb-0" style="margin-bottom: 5px !important">
			&copy; Flourish Change, Inc. 2019-2021
		</p>
		<p class="mb-0">
			Version {{ $root.$data.appVersion }} ({{ $root.$data.appMinor }})
		</p>
	</v-container>
</template>

<script>
export default {
	name: 'trademark-footer'
}
</script>

<style lang="scss" scoped>
.footer-row {
	width: 100% !important;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding-bottom: 25px;
	position: absolute;
	bottom: 0px;
}
</style>
