<template>
	<div id="app" data-app>
		<section class="frame" id="parent">
			<v-app>
				<nav-template>
					<template slot="body">
						<OnboardingDialog />
						<transition :name="transitionName" mode="out-in">
							<router-view :key="routerKey" />
						</transition>
					</template>
				</nav-template>
			</v-app>
		</section>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import navTemplate from './templates/navTemplate.vue'
import { initPushListeners } from '@/utilities/pushNotifications'
import { AppsFlyer } from 'appsflyer-capacitor-plugin'
import OnboardingDialog from '@/components/OnboardingDialog'

export default {
	components: { navTemplate, OnboardingDialog },
	name: 'app',
	data() {
		return {
			transitionName: '',
			hashHistory: [window.location.hash],
			historyLength: window.history.length
		}
	},
	computed: {
		...mapGetters(['user', 'isLoggedIn', 'title']),
		showPrivate() {
			return this.isLoggedIn
		},
		routerKey() {
			if (this.$route.query.oauth_state_id) {
				return this.$route.path
			}
			return this.$route.fullPath
		}
	},
	methods: {
		...mapActions(['updateQuery', 'addInstToOnetimeGift']),

		onSearch(query) {
			this.updateQuery(query)
		},
		onShowDetails(inst) {
			this.updateQuery(inst.name)
			if (this.$route.name === 'one-time-donate') {
				this.addInstToOnetimeGift(inst)
				this.$root.$emit('oneTime-addInstitution')
				return
			}
			let route = this.showPrivate ? '' : 'public-'
			this.$router.push({
				name: route + 'discover-details',
				props: { uuid: inst.uuid },
				params: { uuid: inst.uuid, inst }
			})
		},
		onShowResults(params) {
			this.updateQuery(params.query)
			if (this.$route.name === 'one-time-donate') {
				return
			}

			// only works moving from different page
			if (!this.$route.path.includes('discover/results')) {
				let route = this.showPrivate ? '' : 'public-'
				this.$router.push({
					name: route + 'discover-results',
					query: params
				})
			}
		},
		configureAppsFlyer() {
			const afConfig = {
				appID: '1350497395', // replace with your app ID.
				devKey: 'aRPn9vQdNABu6ncwrcz6D9', // replace with your dev key.
				isDebug: process.env.NODE_ENV !== 'production',
				waitForATTUserAuthorization: 60, // for iOS 14 and higher
				minTimeBetweenSessions: 6, // default 5 sec
				registerOnDeepLink: true,
				registerConversionListener: true,
				registerOnAppOpenAttribution: false,
				deepLinkTimeout: 4000, // default 3000 ms
				useReceiptValidationSandbox: true, // iOS only
				useUninstallSandbox: true // iOS only
			}

			AppsFlyer.initSDK(afConfig).then((res) =>
				console.log('Appsflyer ready: ', res)
			)
		}
	},
	watch: {
		$route(to) {
			var hash = window.location.hash
			var length = window.history.length
			if (this.hashHistory.length && this.historyLength === length) {
				if (this.hashHistory[this.hashHistory.length - 2] === hash) {
					this.hashHistory = this.hashHistory.slice(0, -1)
					this.transitionName = 'slide-right'
				} else if (
					this.hashHistory[this.hashHistory.length - 1] === hash
				) {
					this.hashHistory.push(hash)
				} else {
					this.hashHistory.push(hash)
					this.transitionName = 'slide-left'
				}
			} else {
				this.hashHistory.push(hash)
				this.historyLength = length
			}

			if (to.params.direction === 'back') {
				this.transitionName = 'slide-right'
			} else if (to.params.direction === 'forward') {
				this.transitionName = 'slide-left'
			} else if (to.params.direction === 'up') {
				this.transitionName = 'slide-up'
			} else if (to.params.direction === 'down') {
				this.transitionName = 'slide-down'
			} else if (to.params.direction === 'fade') {
				this.transitionName = 'fade'
			}
		}
	},
	async mounted() {
		this.$root.$on(['search', 'localSearch'], (query) =>
			this.onShowResults({ query })
		)
		this.$root.$on('searchDetail', (inst) => this.onShowDetails(inst))
		// this.$root.$on('localSearch', (query) => this.onSearch(query))
		this.$root.$on('update:search-input-query', (query) =>
			this.onSearch(query)
		)
		if (process.env.VUE_APP_TYPE !== 'web') {
			await initPushListeners()
			this.configureAppsFlyer()
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

* {
	box-sizing: border-box;
}

body,
html {
	margin: 0px;
	padding: 0px env(safe-area-inset-right) env(safe-area-inset-bottom)
		env(safe-area-inset-left) !important;
	background: $roundup-bone;
	font-family: 'Work Sans', Helvetica, Arial, sans-serif;
	color: $roundup-charcoal;
	height: 100%;

	@media screen and (max-width: 480px) {
		.swal2-shown {
			overflow-y: visible !important;
		}
	}
}
#app {
	min-height: 100%;
	overflow-y: auto;
	font-family: 'Work Sans', Helvetica, Arial, sans-serif;
	touch-action: pan-y;
	box-shadow: 0 3px 30px rgb(0 0 0 / 15%);

	.v-application {
		background: $roundup-bone;
	}
}
.frame {
	max-width: 480px;
	margin: 0px auto 0px;
	border-radius: 14px;
	position: relative;
	height: 100%;
	@media screen and (max-width: 960px) {
		max-width: 480px;
	}
}
.slide-left-enter,
.slide-right-leave-active {
	transform: translate(100%, 0);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-left-leave-active,
.slide-right-enter {
	transform: translate(-100%, 0);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-left-enter-active,
.slide-right-enter-active {
	position: absolute;
	@media screen and (max-width: 480px) {
		top: 0px;
	}
}
.slide-left-enter-active,
.slide-right-leave-active,
.slide-right-enter-active,
.slide-left-leave-active {
	transition: all 250ms ease-in-out;
}
.slide-up-enter,
.slide-down-leave-active {
	transform: translate(0, 100%);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-up-leave-active,
.slide-down-enter {
	transform: translate(0, -100%);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-up-enter-active,
.slide-down-enter-active {
	position: absolute;
	top: -20px;
	@media screen and (max-width: 480px) {
		top: 0px;
	}
}
.slide-up-enter-active,
.slide-down-leave-active,
.slide-down-enter-active,
.slide-up-leave-active {
	transition: all 0.25s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.clickable {
	&:hover {
		cursor: pointer;
	}
}

.background-fade {
	background: linear-gradient(180deg, #1cd4e3 0%, #3770fe 100%);
}
.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 100% !important;
}
</style>
