import axios from '../../network/axios'
import { first } from '../../utilities'
import { NOTIF_LIST, NOTIF_LIST_REQUEST } from '../types'

const state = {
	isFetching: false,
	items: []
}

const getters = {
	notifications: (state) => state.items,
	nextNotification: (state) => first(state.items),
	hasNotification: (state) => state.items.length > 0
}

const actions = {
	getNotifications({ commit }) {
		return new Promise((resolve) => {
			commit(NOTIF_LIST_REQUEST)
			axios.get('/notifications').then(({ data }) => {
				commit(NOTIF_LIST, data)
				resolve()
			})
		})
	},
	viewNotification({ dispatch }, notification) {
		return new Promise((resolve) => {
			axios.put('/notifications/' + notification.id).then(() => {
				window.analytics.track('notification_viewed', { notification })
				dispatch('getNotifications')
				resolve()
			})
		})
	}
}

const mutations = {
	[NOTIF_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[NOTIF_LIST](state, data) {
		state.isFetching = false
		state.items = data
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
