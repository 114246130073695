import axios from '../../network/axios'
import { INST_DETAIL, DESIG_LIST } from '../types'
import { analytics } from '@/utilities/tracker'

const state = {
	item: [],
	designations: []
}

const actions = {
	getUserInstitution({ commit, getters, dispatch }, uuid) {
		return new Promise((resolve, reject) => {
			axios
				.get('/user/institutions/detail/' + uuid)
				.then(({ data }) => {
					commit(INST_DETAIL, data)
					resolve(data)
				})
				.catch((e) => reject(e))
		})
	},
	getRandomInstitution() {
		return new Promise((resolve, reject) => {
			axios
				.get('/institutions/random')
				.then(({ data }) => {
					resolve(data)
				})
				.catch((e) => reject(e))
		})
	},
	getInstitution({}, uuid) {
		return new Promise((resolve, reject) => {
			axios
				.get('/institutions/' + uuid)
				.then(({ data }) => {
					resolve(data)
				})
				.catch((e) => reject(e))
		})
	},
	getDesignations({ commit }, institutionId) {
		return new Promise((resolve) => {
			axios
				.get('/institutions/' + institutionId + '/designations')
				.then(({ data }) => {
					commit(INST_DETAIL, { id: institutionId })
					commit(DESIG_LIST, data)
					resolve(data)
				})
		})
	},
	pickDesignation({ state }, designationId) {
		return new Promise((resolve) => {
			axios
				.post('/me/institution/' + state.item.id + '/designation', {
					designation_id: designationId
				})
				.then(({ data }) => {
					window.analytics.track('pick_project', {
						designation_id: designationId
					})
					resolve(data)
				})
		})
	},
	updateMinimumDonation({}, { id, amount }) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/user/institutions/${id}/update-amount`, { amount })
				.then(({ data }) => {
					resolve(data)
				})
				.catch((error) => {
					console.log(error.message)
					reject(error)
				})
		})
	},
	switchGivingType({}, { institution_uuid, amount, roundups_enabled }) {
		return new Promise((resolve, reject) => {
			axios
				.post(`/user/institutions/swap-strategies`, {
					amount,
					institution_uuid,
					roundups_enabled
				})
				.then(({ data }) => {
					resolve(data)
					analytics.track('recurring_donation_type_changed', {
						institution_uuid,
						roundups_enabled: roundups_enabled
					})
				})
				.catch((error) => {
					console.log(error.message)
					reject(error)
				})
		})
	},
	archive({}, { id, institution_uuid, institution_name }) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/user/institutions/${id}/archive`)
				.then(({ data }) => {
					analytics.track('recurring_donation_removed', {
						institution_id: id,
						institution_uuid,
						institution_name
					})
					resolve(data)
				})
				.catch((error) => {
					console.log(error.message)
					reject(error)
				})
		})
	},
	pause({}, { id, institution_uuid, institution_name }) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/user/institutions/${id}/pause`)
				.then(({ data }) => {
					analytics.track('recurring_donation_paused', {
						institution_id: id,
						institution_uuid,
						institution_name
					})
					resolve(data)
				})
				.catch((error) => {
					console.log(error.message)
					reject(error)
				})
		})
	},
	unpause({ dispatch }, { id, institution_uuid, institution_name }) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/user/institutions/${id}/unpause`)
				.then(({ data }) => {
					analytics.track('recurring_donation_unpaused', {
						institution_id: id,
						institution_uuid,
						institution_name
					})
					dispatch('getMyInstitutions').then(() => resolve(data))
				})
				.catch((error) => {
					console.log(error.message)
					reject(error)
				})
		})
	},
	confirmDonation({ dispatch }, { id, institution_uuid, institution_name }) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/user/institutions/${id}/confirm`)
				.then(({ data }) => {
					analytics.track('recurring_donation_confirmed', {
						institution_id: id,
						institution_uuid,
						institution_name
					})
					dispatch('getMyInstitutions').then(() => resolve(data))
				})
				.catch((error) => {
					console.log(error)
					reject(error)
				})
		})
	}
}

const mutations = {
	[INST_DETAIL](state, institution) {
		state.item = institution
	},
	[DESIG_LIST](state, designations) {
		state.designations = designations
	}
}

export default {
	state,
	actions,
	mutations
}
