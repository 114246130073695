<template>
	<p>
		By donating through RoundUp App you agree to the
		<a href="https://roundupapp.com/privacy" target="_blank">
			privacy policy</a
		>
		and
		<a href="https://roundupapp.com/terms" target="_blank">terms of use.</a>
	</p>
</template>

<script>
export default {
	name: 'tos-pp'
}
</script>

<style lang="scss" scoped>
p {
	font-size: 0.75rem !important;
	color: $roundup-slate !important;
	a {
		font-size: inherit !important;
	}
}
</style>
