<template>
	<div class="line-graph">
		<canvas ref="graph"></canvas>
	</div>
</template>

<script>
import Chart from 'chart.js/auto'
import { getDaysArray } from '../utilities'
import moment from 'moment'

export default {
	name: 'line-graph',
	props: {
		chartData: {
			type: Array,
			default: function () {
				return []
			}
		},
		append: {
			type: String,
			default: ''
		},
		prepend: {
			type: String,
			default: '$'
		},
		defaultGrowth: {
			type: Number,
			default: 5
		},
		datatype: {
			type: String,
			default: 'money'
		}
	},
	computed: {
		dataset() {
			let cd = this.chartData

			const dataset = {
				borderWidth: 1,
				lineTension: 0,
				pointRadius: 2,
				fill: true,
				tension: 0.25
			}

			var data = []
			var labels = []
			if (!cd || cd.length === 0) {
				let end = new Date()
				let start = new Date().setDate(end.getDate() - 30)

				cd = getDaysArray(start, end)
				cd = cd.map((day, i) => {
					return {
						date: moment(day).format('l'),
						value: Math.floor(
							Math.random() *
								(this.defaultGrowth * i -
									this.defaultGrowth *
										(i + this.defaultGrowth)) +
								this.defaultGrowth * (i + this.defaultGrowth)
						)
					}
				})
				labels = cd.map(() => '')
				dataset.backgroundColor = 'rgba(220, 222, 227, .5)'
			} else {
				if (this.$refs.graph) {
					this.gradient = this.$refs.graph
						.getContext('2d')
						.createLinearGradient(0, 0, 0, 450)

					this.gradient.addColorStop(0, 'rgba(28, 212, 227, .8)')
					this.gradient.addColorStop(1, 'rgba(55, 112, 254, .8)')
				}

				dataset.borderColor = '#2553CD'
				dataset.pointBorderColor = '#2553CD'
				dataset.pointBackgroundColor = '#2553CD'
				dataset.backgroundColor = this.gradient
				labels = cd.map((item) => item.date)
			}
			data = cd.map((item) => item.value)

			dataset.data = data
			return {
				labels,
				datasets: [dataset]
			}
		}
	},
	data() {
		const prefix = this.prepend
		const type = this.datatype
		let max
		if (this.chartData && this.chartData.length) {
			const vals = this.chartData.map((d) => d.value)
			max = Math.floor(Math.max(...vals) * 1.2)
		}

		return {
			chart: null,
			options: {
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						callbacks: {
							label: function (context) {
								var label = context.dataset.label || ''

								if (label) {
									label += ': '
								}
								if (
									context.parsed.y !== null &&
									type === 'money'
								) {
									label += new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'USD'
									}).format(context.parsed.y)
								} else if (context.parsed.y !== null) {
									label += prefix + context.parsed.y
								}
								return label
							}
						}
					}
				},
				scales: {
					x: {
						display: true
					},
					y: {
						display: true,
						ticks: {
							beginAtZero: true,
							callback: function (val, index) {
								return (
									prefix +
									val
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								)
							}
						},
						max,
						min: 0
					}
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							return (
								prefix +
								// eslint-disable-next-line
								data['datasets'][0]['data'][
									tooltipItem['index']
								]
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							)
						}
					}
				},
				responsive: true,
				maintainAspectRatio: false
			},
			gradient: null
		}
	},
	methods: {
		reload() {
			this.chart.destroy()
			this.chart = new Chart(this.$refs.graph, {
				type: 'line',
				data: this.dataset,
				options: this.options
			})
		}
	},
	mounted() {
		this.chart = new Chart(this.$refs.graph, {
			type: 'line',
			data: this.dataset,
			options: this.options
		})
	},
	watch: {
		dataset() {
			this.reload()
		}
	}
}
</script>

<style lang="scss">
.line-graph {
	padding: 10px 20px;
	height: 300px;
}
</style>
