<template>
	<v-card :style="{ backgroundColor: backgroundColor, minHeight: '100px' }">
		<v-row v-if="index % 1 === 0" class="ma-4 mb-0">
			<v-col
				cols="3"
				style="
					justify-content: center;
					align-items: center;
					display: flex;
				"
			>
				<CommunityStoryImg :type="story.type"></CommunityStoryImg>
			</v-col>
			<v-col
				><h3 :style="{ color: fontColor }">{{ story.title }}</h3>
				<p :style="{ color: fontColor }">{{ story.summary }}</p></v-col
			>
		</v-row>
		<v-row class="ma-4 mt-0 pb-4" v-if="story.call_to_action_message">
			<v-spacer></v-spacer>
			<Roundup-Button
				@click="handleClick"
				color="clear"
				:style="{ color: fontColor }"
				>{{ story.call_to_action_message
				}}<v-icon small class="ml-2">fas fa-chevron-right</v-icon>
			</Roundup-Button>
		</v-row>
	</v-card>
</template>

<script>
import CommunityStoryImg from '@/components/StoryCard/storyCardImg'
import { Browser } from '@capacitor/browser'

export default {
	name: 'community-story-card',
	props: ['story', 'index'],
	components: { CommunityStoryImg },
	computed: {
		backgroundColor() {
			const colors = ['#5144bf', '#9bb8ff']

			return colors[this.index % colors.length]
		},
		fontColor() {
			const colors = ['white', 'inherit']

			return colors[this.index % colors.length] + ' !important'
		}
	},
	methods: {
		handleError(err) {
			console.log(err)
		},
		async handleClick() {
			await Browser.open({ url: this.story.external_url })
		}
	}
}
</script>

<style></style>
