import axios from '../../network/axios'
import { get } from '../../utilities'
import {
	GET_DONATION_GRAPH,
	SET_DONATION_GRAPH,
	SET_DONATION_LIST_ERROR,
	GET_DONATION_LIST,
	SET_DONATION_LIST,
	GET_NEXT_DONATION,
	SET_NEXT_DONATION,
	GET_DONATION_LIST_BY_YEAR,
	SET_DONATION_LIST_BY_YEAR,
	GET_FAILED_DONATIONS,
	SET_FAILED_DONATIONS,
	REPROCESS_FAILED_DONATIONS,
	REPROCESS_FAILED_DONATIONS_SUCCESS,
	REPROCESS_FAILED_DONATIONS_ERROR
} from '../types'

const state = {
	isFetching: false,
	graphFetching: false,
	items: [],
	graph: [],
	nextDonation: 0,
	err: null,
	failedDonations: [],
	byYear: []
}

const getters = {
	donationGraph: (state) => state.graph,
	donationsByYear: (state) => state.byYear,
	hasDonationHistory: (state) =>
		get(state, 'items', []).length > 0 ||
		Object.keys(get(state, 'byYear', {})) > 0,
	recentDonations: (state) =>
		state.items
			.slice(0, 5)
			.sort(
				(a, b) =>
					new Date(b.processed_date).getTime() -
					new Date(a.processed_date).getTime()
			),
	totalDonationsForYear: (state) => {
		let total = 0

		for (var ui of state.items) {
			let processedYear = new Date(ui.processed_date).getFullYear()
			let currentYear = new Date().getFullYear()

			if (processedYear === currentYear) {
				total += ui.total_amount_captured
			}
		}
		return total
	},
	nextDonation: (state) => state.nextDonation,
	failedDonations: (state) => state.failedDonations
}

const actions = {
	getDonations({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_DONATION_LIST)
			axios
				.get('/user/donations')
				.then(({ data }) => {
					commit(SET_DONATION_LIST, data)
					resolve()
				})
				.catch((err) => {
					commit(SET_DONATION_LIST_ERROR, err)
					reject(err)
				})
		})
	},
	getNextDonation({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_NEXT_DONATION)
			axios
				.get('/user/institutions/donations/next')
				.then(({ data }) => {
					commit(SET_NEXT_DONATION, data)
					resolve()
				})
				.catch((err) => {
					commit(SET_DONATION_LIST_ERROR, err)
					reject(err)
				})
		})
	},
	getDonationsByYear({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_DONATION_LIST_BY_YEAR)
			axios
				.get('/user/donations/byYear')
				.then(({ data }) => {
					commit(SET_DONATION_LIST_BY_YEAR, data)
					resolve()
				})
				.catch((err) => {
					commit(SET_DONATION_LIST_ERROR, err)
					reject(err)
				})
		})
	},
	getDonationsGraph({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_DONATION_GRAPH)
			axios
				.get('/user/donations/graph')
				.then(({ data }) => {
					const formattedData = data.map((d) => {
						return { date: d.date, value: d.value / 100 }
					})
					commit(SET_DONATION_GRAPH, formattedData)
					resolve()
				})
				.catch((err) => {
					commit(SET_DONATION_LIST_ERROR, err)
					reject(err)
				})
		})
	},
	getFailedDonations({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_FAILED_DONATIONS)
			axios
				.get('/user/donations/failures')
				.then(({ data }) => {
					commit(SET_FAILED_DONATIONS, data)
					resolve()
				})
				.catch((err) => {
					commit(SET_DONATION_LIST_ERROR, err)
					reject(err)
				})
		})
	},
	reprocessDonations({ commit, dispatch }, ids) {
		return new Promise((resolve, reject) => {
			commit(REPROCESS_FAILED_DONATIONS)
			axios
				.post('/user/donations/reprocess', { payout_id_list: ids })
				.then(() => {
					dispatch('getFailedDonations')
					commit(REPROCESS_FAILED_DONATIONS_SUCCESS)
					resolve()
				})
				.catch(({ response }) => {
					commit(REPROCESS_FAILED_DONATIONS_ERROR, response.data)
					reject(response.data)
				})
		})
	}
}

const mutations = {
	[GET_DONATION_LIST](state) {
		state.error = null
		state.isFetching = true
	},
	[GET_NEXT_DONATION](state) {
		state.error = null
		state.isFetching = true
	},
	[SET_NEXT_DONATION](state, data) {
		state.nextDonation = data.nextDonation
	},
	[SET_DONATION_LIST](state, data) {
		state.isFetching = false
		state.items = data.data
	},
	[SET_DONATION_LIST_ERROR](state, err) {
		state.isFetching = false
		state.graphFetching = false
		state.err = err
	},
	[SET_DONATION_GRAPH](state, data) {
		state.graphFetching = false
		state.graph = data
	},
	[GET_DONATION_GRAPH](state) {
		state.graphFetching = true
	},
	[GET_DONATION_LIST_BY_YEAR](state) {
		state.isFetching = true
	},
	[SET_DONATION_LIST_BY_YEAR](state, data) {
		state.isFetching = false
		state.byYear = data
	},
	[GET_FAILED_DONATIONS](state) {
		state.isFetching = true
	},
	[SET_FAILED_DONATIONS](state, item) {
		state.isFetching = false
		state.failedDonations = item
	},
	[REPROCESS_FAILED_DONATIONS](state) {
		state.isFetching = true
	},
	[REPROCESS_FAILED_DONATIONS_SUCCESS](state) {
		state.isFetching = false
	},
	[REPROCESS_FAILED_DONATIONS_ERROR](state, err) {
		state.isFetching = false
		state.err = err
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
