import { reject } from 'lodash'
import axios from '../../network/axios'
import { get } from '../../utilities'
import {
	SET_COMMUNITY_EVENTS_DETAIL_LIST,
	GET_COMMUNITY_EVENTS_DETAIL_LIST,
	SET_COMMUNITY_EVENTS_DETAIL_LIST_ERROR
} from '../types'

const state = {
	event: {},
	isFetching: false,
	error: null
}

const getters = {}

const actions = {
	getCommunityEventsDetail({ commit, state }, uuid) {
		return new Promise((resolve, reject) => {
			commit(GET_COMMUNITY_EVENTS_DETAIL_LIST)
			resolve(state.event)
			axios
				.get('/community/events/' + uuid)
				.then(({ data }) => {
					commit(SET_COMMUNITY_EVENTS_DETAIL_LIST, data)
					resolve()
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
}

const mutations = {
	[SET_COMMUNITY_EVENTS_DETAIL_LIST](state, event) {
		state.isFetching = false
		state.error = null
		state.event = event
	},
	[GET_COMMUNITY_EVENTS_DETAIL_LIST](state) {
		state.isFetching = true
		state.error = null
	},
	[SET_COMMUNITY_EVENTS_DETAIL_LIST_ERROR](state, error) {
		state.isFetching = false
		state.error = error
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
