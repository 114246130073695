// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

import navigate from './mixins/navigate'
import alert from './mixins/alert'
import { initFacebookSdk } from './utilities/initFacebook'
import VueHead from 'vue-head'
import VueGtm from 'vue-gtm'
import VueSocialSharing from 'vue-social-sharing'
import VueMoment from 'vue-moment'
import VueMask from 'v-mask'
import moment from 'moment-timezone'
import vuetify from './plugins/vuetify'
import packageFile from '/package'
import VueClipboard from 'vue-clipboard2'

import Components from '@roundupapp/component-library'

import navTemplate from './templates/navTemplate.vue'
import { Container } from './ui'
import * as VueGoogleMaps from 'vue2-google-maps'

for (var k in Components) {
	if (!k.includes('config')) {
		Vue.component(k, Components[k])
	}
}

Vue.component('nav-template', navTemplate)
Vue.component('container', Container)
Vue.use(navigate)
Vue.use(alert)
Vue.use(moment)
Vue.use(VueMask)
Vue.use(VueClipboard)
Vue.use(VueHead)
Vue.use(VueMoment)
Vue.use(VueSocialSharing, {
	networks: {
		sms: `sms:?&body=@d`,
		email: `mailto:?&subject=@t&body=@d`
	}
})
Vue.use(VueGtm, {
	id: process.env.VUE_APP_GTM,
	// queryParams: {
	// 	// Add url query string when load gtm.js with GTM ID (optional)
	// 	gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
	// 	gtm_preview: 'env-4',
	// 	gtm_cookies_win: 'x'
	// },
	defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: false, // Whether or not display console logs debugs (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	// ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
	trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
		libraries: 'places' // This is required if you use the Autocomplete plugin
		// OR: libraries: 'places,drawing'
		// OR: libraries: 'places,drawing,visualization'
		// (as you require)

		//// If you want to set the version, you can do so:
		// v: '3.26',
	},

	//// If you intend to programmatically custom event listener code
	//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
	//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
	//// you might need to turn this on.
	// autobindAllEvents: false,

	//// If you want to manually install components, e.g.
	//// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
	//// Vue.component('GmapMarker', GmapMarker)
	//// then disable the following:
	installComponents: true
})
Vue.config.productionTip = false

const isMatching =
	new Date().getFullYear() === 2021 && new Date().getMonth() === 11
function startApp() {
	new Vue({
		router,
		store,
		vuetify,
		data: {
			appVersion: packageFile.version,
			appMinor: packageFile.minor,
			ios: process.env.VUE_APP_TYPE === 'ios',
			showCarbon: process.env.VUE_APP_CARBON === 'true',
			showCoverFees: process.env.VUE_APP_COVER_FEES === 'true',
			isMatching
		},
		render: (h) => h(App)
	}).$mount('#app')
}

try {
	initFacebookSdk().then(startApp())
} catch (e) {
	startApp()
}
