import axios from '../../network/axios'
import { GROUP_DETAIL } from '../types'

const state = {
	item: []
}

const actions = {
	getGroup({ commit }, uuid) {
		return new Promise((resolve, reject) => {
			axios
				.get('/institutions/groups/' + uuid)
				.then(({ data }) => {
					commit(GROUP_DETAIL, data)
					resolve(data)
				})
				.catch(() => reject())
		})
	}
}

const mutations = {
	[GROUP_DETAIL](state, group) {
		state.item = group
	}
}

export default {
	state,
	actions,
	mutations
}
