import { reject } from 'lodash'
import axios from '../../network/axios'
import {
	SET_DONATION_DETAIL,
	GET_DONATION_DETAIL,
	SET_DONATION_DETAIL_ERROR
} from '../types'

const state = {
	isFetching: false,
	item: {},
	paid: true,
	error: null,
	errors: '',
	hasFailedPayout: false
}

const getters = {}

const actions = {
	getDonationDetail({ commit }, uuid) {
		return new Promise((resolve, reject) => {
			commit(GET_DONATION_DETAIL)
			axios
				.get(`/user/donations/${uuid}`)
				.then(({ data }) => {
					commit(SET_DONATION_DETAIL, data)
					resolve(data)
				})
				.catch((error) => {
					console.log(error)
					reject()
					commit(SET_DONATION_DETAIL_ERROR, error)
				})
		})
	}
}

const mutations = {
	[GET_DONATION_DETAIL](state) {
		state.error = null
		state.isFetching = true
	},
	[SET_DONATION_DETAIL_ERROR](state, error) {
		state.isFetching = false
		state.error = error
	},
	[SET_DONATION_DETAIL](state, data) {
		state.isFetching = false
		state.item = data
		state.paid = data.payouts
			? data.payouts.some((p) => !p.stripe_error)
			: false

		state.hasFailedPayout = data.payouts
			? data.payouts.filter((p) => p.stripe_error).length > 0
			: false

		let errors
		if (data.payouts) {
			if (data.payouts.length > 1) {
				errors = data.payouts
					.map((p) => p.stripe_error)
					.filter((n) => n)
					.join(', ')
			} else {
				errors = ''
			}
		}

		state.errors = errors
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
