import axios from '../../network/axios'
import {
	ROUNDUP_LIST,
	ROUNDUP_LIST_REQUEST,
	ROUNDUP_LIST_REQUEST_ERROR
} from '../types'

const state = {
	isFetching: false,
	graphFetching: false,
	items: [],
	graph: [],
	err: null,
	byYear: {}
}

const getters = {
	roundupsByYear: (state) => state.byYear,
	hasRoundupHistory: (state) => state.items.length > 0,
	recentRoundups: (state) => state.items.slice(0, 5)
}

const actions = {
	getRoundups({ commit }) {
		return new Promise((resolve) => {
			commit(ROUNDUP_LIST_REQUEST)
			axios
				.get('/roundups')
				.then(({ data }) => {
					commit(ROUNDUP_LIST, data)
					resolve()
				})
				.catch((error) => {
					commit(ROUNDUP_LIST_REQUEST_ERROR, error)
				})
		})
	}
}

const mutations = {
	[ROUNDUP_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[ROUNDUP_LIST_REQUEST_ERROR](state, error) {
		state.isFetching = false
		state.error = error
	},
	[ROUNDUP_LIST](state, data) {
		state.isFetching = false
		state.items = data.roundups
		state.byYear = data.byYear
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
