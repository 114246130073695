/* eslint no-multi-spaces: ["error", { exceptions: { "VariableDeclarator": true } }] */

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_HEADERS = 'SET_HEADERS'
export const REGISTER_REQUEST_ERROR = 'REGISTER_REQUEST_ERROR'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'

export const USER_SET_CURRENT = 'USER_SET_CURRENT'
export const USER_REMOVE_CURRENT = 'USER_REMOVE_CURRENT'
export const USER_ACCOUNTS = 'USER_ACCOUNTS'
export const USER_SUCCESS_STEP = 'USER_SUCCESS_STEP'
export const USER_SET_TEMP = 'USER_SET_TEMP'
export const USER_REMOVE_TEMP = 'USER_REMOVE_TEMP '

export const USER_SET_UNSAVED_FORM = 'USER_SET_UNSAVED_FORM'
export const USER_SET_SHOW_UNSAVED_FORM_DIALOG =
	'USER_SET_SHOW_UNSAVED_FORM_DIALOG'

export const ACCOUNT_LIST = 'ACCOUNT_LIST'
export const ACCOUNT_LIST_REQUEST = 'ACCOUNT_LIST_REQUEST'
export const ACCOUNT_TOGGLE = 'ACCOUNT_TOGGLE'

export const LINK_LIST = 'LINK_LIST'
export const LINK_LIST_REQUEST = 'LINK_LIST_REQUEST'

// User Institutions

export const INST_DETAIL = 'INST_DETAIL'
export const INST_LIST = 'INST_LIST'
export const ADD_TO_ONE_TIME_LIST = 'ADD_TO_ONE_TIME_LIST'
export const GET_PROJECTED_ROUNDUPS = 'GET_PROJECTED_ROUNDUPS'
export const SET_PROJECTED_ROUNDUPS = 'SET_PROJECTED_ROUNDUPS'
export const SET_INST_LIST_ERROR = 'SET_INST_LIST_ERROR'
export const CATEGORY_LIST = 'CATEGORY_LIST'
export const INST_LIST_REQUEST = 'INST_LIST_REQUEST'
export const DESIG_LIST = 'DESIG_LIST'

export const GROUP_LIST = 'GROUP_LIST'
export const GROUP_DETAIL = 'GROUP_DETAIL'
export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST'

export const HOME_LIST = 'HOME_LIST'
export const HOME_LIST_REQUEST = 'HOME_LIST_REQUEST'

export const TRANS_LIST = 'TRANS_LIST'
export const TRANS_LIST_REQUEST = 'TRANS_LIST_REQUEST'

// Roundup lists
export const ROUNDUP_LIST = 'ROUNDUP_LIST'
export const ROUNDUP_LIST_REQUEST = 'ROUNDUP_LIST_REQUEST'
export const ROUNDUP_LIST_REQUEST_ERROR = 'ROUNDUP_LIST_REQUEST_ERROR'

// Roundup details
export const ROUNDUP_DETAIL_REQUEST = 'ROUNDUP_DETAIL_REQUEST'
export const ROUNDUP_DETAIL = 'ROUNDUP_DETAIL'
export const ROUNDUP_DETAIL_ERROR = 'ROUNDUP_DETAIL_ERROR'

// Donation lists
export const GET_DONATION_GRAPH = 'GET_DONATION_GRAPH'
export const SET_DONATION_GRAPH = 'SET_DONATION_GRAPH'
export const SET_DONATION_LIST_ERROR = 'SET_DONATION_LIST_ERROR'
export const GET_DONATION_LIST = 'GET_DONATION_LIST'
export const SET_DONATION_LIST = 'SET_DONATION_LIST'
export const GET_NEXT_DONATION = 'GET_NEXT_DONATION'
export const SET_NEXT_DONATION = 'SET_NEXT_DONATION'
export const GET_DONATION_LIST_BY_YEAR = 'GET_DONATION_LIST_BY_YEAR_REQUEST'
export const SET_DONATION_LIST_BY_YEAR = 'SET_DONATION_LIST_BY_YEAR'
export const GET_FAILED_DONATIONS = 'GET_FAILED_DONATIONS'
export const SET_FAILED_DONATIONS = 'SET_FAILED_DONATIONS'
export const REPROCESS_FAILED_DONATIONS_SUCCESS =
	'REPROCESS_FAILED_DONATIONS_SUCCESS'
export const REPROCESS_FAILED_DONATIONS_ERROR =
	'REPROCESS_FAILED_DONATIONS_ERROR'
export const REPROCESS_FAILED_DONATIONS = 'REPROCESS_FAILED_DONATIONS'

// Donation details
export const GET_DONATION_DETAIL = 'GET_DONATION_DETAIL'
export const SET_DONATION_DETAIL = 'SET_DONATION_DETAIL'
export const SET_DONATION_DETAIL_ERROR = 'SET_DONATION_DETAIL_ERROR'

export const NOTIF_LIST = 'NOTIF_LIST'
export const NOTIF_LIST_REQUEST = 'NOTIF_LIST_REQUEST'

// Notification preferences

export const NOTIF_PREF_DETAIL = 'NOTIF_PREF_DETAIL'
export const NOTIF_PREF_DETAIL_REQUEST = 'NOTIF_PREF_DETAIL_REQUEST'

// Scheduled donation
export const SET_SCHEDULED_DONATION_AMOUNT = 'SET_SCHEDULED_DONATION_AMOUNT'
export const SET_SCHEDULED_DONATION_FEE_AMOUNT =
	'SET_SCHEDULED_DONATION_FEE_AMOUNT'
export const SET_SCHEDULED_DONATION_FEE_ENABLED =
	'SET_SCHEDULED_DONATION_FEE_ENABLED'
export const SET_SCHEDULED_DONATION_FEE_DISABLED =
	'SET_SCHEDULED_DONATION_FEE_DISABLED'
export const SET_SCHEDULED_DONATION_TYPE = 'SET_SCHEDULED_DONATION_TYPE'
export const SET_SCHEDULED_DONATION_INSTITUTION =
	'SET_SCHEDULED_DONATION_INSTITUTION'
export const SET_SCHEDULED_DONATION_GROUP = 'SET_SCHEDULED_DONATION_GROUP'
export const SET_SCHEDULED_DONATION_CARD = 'SET_SCHEDULED_DONATION_CARD'
export const SET_SCHEDULED_DONATION_ROUNDUPS_ENABLED =
	'SET_SCHEDULED_DONATION_ROUNDUPS_ENABLED'
export const RESET_SCHEDULED_DONATION = 'RESET_SCHEDULED_DONATION'
export const SET_SCHEDULE_DONATION_FAIL = 'SET_SCHEDULE_DONATION_FAIL'
export const SET_SCHEDULE_DONATION = 'SET_SCHEDULE_DONATION'
export const SET_SCHEDULE_DONATION_SUCCESS = 'SET_SCHEDULE_DONATION_SUCCESS'
export const SET_SCHEDULED_DONATION_EVENT = 'SET_SCHEDULED_DONATION_EVENT'
export const SET_SCHEDULED_USER_INSTITUTION = 'SET_SCHEDULED_USER_INSTITUTION'

// Search Algolia
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY'
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS'
export const SEARCH_LIST_FETCHING = 'SEARCH_LIST_FETCHING'
export const SEARCH_LIST_SUCCESS = 'SEARCH_LIST_SUCCESS'
export const SEARCH_LIST_FAIL = 'SEARCH_LIST_FAIL'
export const UPDATE_LAT_LONG = 'UPDATE_LAT_LONG'

// Algolia Search Tracking

export const EVENTS_LIST_SEND = 'EVENTS_LIST_SEND'
export const ALGOLIA_EVENTS_LIST_SET = 'ALGOLIA_EVENTS_LIST_SET'
export const SEND_SET = 'SEND_SET'
export const QUERYID_SET = 'QUERYID_SET'
export const ALGOLIA_EVENTS_LIST_RESET = 'ALGOLIA_EVENTS_LIST_RESET'

// Task Lists
export const GET_TASK_LIST = 'GET_TASK_LIST'
export const GET_TASK_LIST_ERROR = 'GET_TASK_LIST_ERROR'
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS'

// Nav
export const SET_NAV_ONBOARDING = 'SET_NAV_ONBOARDING'
export const SET_NAV_INSTITUTION = 'SET_NAV_INSTITUTION'
export const SET_SHOW_NAV_HEADER = 'SET_SHOW_NAV_HEADER'
export const SET_SHOW_NAV_FOOTER = 'SET_SHOW_NAV_FOOTER'
export const SET_NAV_TITLE = 'SET_NAV_TITLE'
export const SET_SHOW_BACK = 'SET_SHOW_BACK'
export const SET_NAV_ROUTE_TO = 'SET_NAV_ROUTE_TO'
export const SET_SHOW_NAV_RIGHT_BUTTON = 'SET_SHOW_NAV_RIGHT_BUTTON'
export const SET_NAV_RIGHT_BUTTON_TEXT = 'SET_NAV_RIGHT_BUTTON_TEXT'
export const SET_NAV_HEADER_PROMINENT = 'SET_NAV_HEADER_PROMINENT'
export const RESET_NAV = 'RESET_NAV'
export const SET_NAV_EVENT = 'SET_NAV_EVENT'

// Alert

export const SET_ALERT_TIMEOUT = 'SET_ALERT_TIMEOUT'
export const SET_SHOW_ALERT = 'SET_SHOW_ALERT'
export const SET_ALERT_COLOR = 'SET_ALERT_COLOR'
export const SET_ALERT_POSITION = 'SET_ALERT_POSITION'
export const SET_ALERT_TEXT = 'SET_ALERT_TEXT'
export const RESET_ALERT = 'RESET_ALERT'
export const SET_ALERT_COUNT = 'SET_ALERT_COUNT'

// Onetime Donations
export const ONETIME_DONATION_REQUEST = 'ONETIME_DONATION_REQUEST'
export const ONETIME_DONATION_SUCCESS = 'ONETIME_DONATION_SUCCESS'
export const ONETIME_DONATION_ERROR = 'ONETIME_DONATION_ERROR'

// onboarding
export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP'

// community events
export const SET_COMMUNITY_EVENTS_LIST = 'SET_COMMUNITY_EVENTS_LIST'
export const GET_COMMUNITY_EVENTS_LIST = 'GET_COMMUNITY_EVENTS_LIST'
export const SET_COMMUNITY_EVENTS_LIST_ERROR = 'SET_COMMUNITY_EVENTS_LIST_ERROR'
export const SET_COMMUNITY_EVENTS_DETAIL_LIST =
	'SET_COMMUNITY_EVENTS_DETAIL_LIST'
export const GET_COMMUNITY_EVENTS_DETAIL_LIST =
	'GET_COMMUNITY_EVENTS_DETAIL_LIST'
export const SET_COMMUNITY_EVENTS_DETAIL_LIST_ERROR =
	'SET_COMMUNITY_EVENTS_DETAIL_LIST_ERROR'

export const SET_COMMUNITY_STORIES_LIST = 'SET_COMMUNITY_STORIES_LIST'
export const GET_COMMUNITY_STORIES_LIST = 'GET_COMMUNITY_STORIES_LIST'
export const SET_COMMUNITY_STORIES_LIST_ERROR =
	'SET_COMMUNITY_STORIES_LIST_ERROR'

export const SET_BANNER_LIST_ERROR = 'SET_BANNER_LIST_ERROR'
export const GET_BANNER_LIST = 'GET_BANNER_LIST'
export const SET_BANNER_LIST = 'SET_BANNER_LIST'
