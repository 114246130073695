<template>
	<div
		class="nav-header"
		:class="[nav.prominent && 'prominent']"
		:style="{ 'padding-top': headerPaddingTop }"
		fluid
	>
		<div class="roundup-row nav-container">
			<div
				@click="handleClick"
				v-if="nav.showBack"
				class="nav-left-button-wrapper roundup-row"
			>
				<v-icon class="nav-left-button"> fas fa-arrow-left </v-icon>
			</div>

			<div class="nav-center-button-wrapper roundup-row">
				<p style="color: white; font-size: 16px" class="ma-0">
					{{ nav.title }}
				</p>
			</div>
			<div class="nav-right-button-wrapper roundup-row">
				<v-spacer
					v-if="!nav.showNavHeaderRightButton"
					style="flex: 1"
				/>
				<div
					v-else
					style="cursor: pointer"
					@click="$emit('navButtonClick')"
				>
					<p class="ma-0" style="color: white; font-weight: 600">
						Save
						{{ nav.navHeaderRightButtonText }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Capacitor } from '@capacitor/core'

export default {
	name: 'nav-header',
	data: () => ({}),
	computed: {
		...mapState({
			nav: ({ nav }) => nav
		}),
		...mapGetters(['routeTo']),
		headerPaddingTop() {
			if (Capacitor.getPlatform() === 'ios') {
				return '24px'
			}
			return '0px'
		}
	},
	methods: {
		isActive(name) {
			return name === this.$router.history.current.name
		},
		handleClick() {
			if (this.routeTo.length > 0) {
				this.navigate({ name: this.routeTo }, 'back')
			} else {
				this.navigate({}, 'back', 'back')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.nav-header {
	background-color: $roundup-primary;
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	z-index: 10;
	&.prominent {
		padding-top: 40px !important;
	}

	.nav-container {
		height: 56px;
	}
	.nav-left-button-wrapper {
		cursor: pointer;
		height: 56px;
		width: 50px;
		position: absolute;
		left: 0px !important;
		.nav-left-button {
			font-size: 18px;
			color: white;
		}
	}

	.nav-center-button-wrapper {
		flex: 2;
		flex-grow: 10;
	}

	.nav-right-button-wrapper {
		position: absolute;
		right: 16px !important;
	}

	.col {
		display: flex;
	}
	.v-icon::after {
		height: 20px;
		width: 20px;
		left: -3px;
		top: 0px;
	}
}
</style>
