import axios from '../../network/axios'
import moment from 'moment'
import { HOME_LIST, HOME_LIST_REQUEST } from '../types'

const state = {
	isFetching: false,
	item: {}
}

const getters = {
	// nextDonation: (state, getters) => {
	// 	var amount = state.item.nextRoundup
	// 	var max = state.item.maxDonation || Number.MAX_SAFE_INTEGER
	// 	var min = state.item.minDonation

	// 	if (!getters.isDonating) {
	// 		return null
	// 	}
	// 	return new Intl.NumberFormat('en-US', {
	// 		style: 'currency',
	// 		currency: 'USD'
	// 	}).format(Math.min(max, Math.max(amount * 100, min)) / 100)
	// },
	currentMonthlyTotal: (state) => state.item.currentMonthlyTotal,
	nextDonationDate: () => {
		return moment()
			.date(1)
			.add(moment().date() > 15 ? 1 : 0, 'months')
			.add(14, 'days')
			.format('MMM DD, YYYY')
	},
	lastDonationDate: (state) =>
		new Date(state.item.lastPayout.created_at).toLocaleDateString(),
	failedPayout: (state) => {
		const { lastPayout } = state.item
		const error = lastPayout ? lastPayout.stripe_error : ''
		if (error) {
			if (error.toLowerCase().includes('refund')) {
				return ''
			}
			return lastPayout.stripe_error
		}
		return ''
	},
	failedTransactions: (state) => state.item.failedPayouts,
	unlinkedAccounts: (state) => state.item.unlinkedAccounts,
	hasMultipleCauses: ({ item }) => {
		if (!item || !item.institutionList) return true
		return item.institutionList.length > 3
	}
}

const actions = {
	getHome({ commit }) {
		return new Promise((resolve, reject) => {
			commit(HOME_LIST_REQUEST)
			axios
				.get('/me/home')
				.then(({ data }) => {
					commit(HOME_LIST, data)
					resolve(data)
				})
				.catch((err) => reject(err))
		})
	}
}

const mutations = {
	[HOME_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[HOME_LIST](state, data) {
		state.isFetching = false
		state.item = data
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
