import {
	SET_ALERT_TIMEOUT,
	SET_SHOW_ALERT,
	SET_ALERT_COLOR,
	SET_ALERT_POSITION,
	SET_ALERT_TEXT,
	RESET_ALERT,
	SET_ALERT_COUNT
} from '../types'

const state = {
	alertTimeout: 3000,
	showAlert: false,
	alertColor: 'success',
	alertPosition: {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	},
	alertText: '',
	alertCount: undefined
}

const getters = {
	alertTimeout: (state) => state.alertTimeout,
	showAlert: (state) => state.showAlert,
	alertColor: (state) => state.alertColor,
	alertPosition: (state) => state.alertPosition,
	alertText: (state) => state.alertText
}

const actions = {
	setAlertTimeout({ commit }, timeout) {
		commit(SET_ALERT_TIMEOUT, timeout)
	},
	setShowAlert({ commit, state }, payload) {
		commit(SET_SHOW_ALERT, payload)
		setTimeout(() => {
			commit(RESET_ALERT, {
				alertTimeout: 3000,
				showAlert: false,
				alertColor: 'success',
				alertPosition: {
					top: -80,
					bottom: 0,
					left: 0,
					right: 0
				},
				alertText: ''
			})
		}, state.alertTimeout)
	},
	setAlertColor({ commit }, color) {
		commit(SET_ALERT_COLOR, color)
	},
	setAlertPosition({ commit }, payload) {
		commit(SET_ALERT_POSITION, payload)
	},
	setAlertText({ commit }, text) {
		commit(SET_ALERT_TEXT, text)
	},
	setAlertCount({ commit }, count) {
		commit(SET_ALERT_COUNT, count)
	}
}

const mutations = {
	[SET_ALERT_TIMEOUT](state, timeout) {
		state.alertTimeout = timeout
	},
	[SET_SHOW_ALERT](state, payload) {
		state.showAlert = payload
	},
	[SET_ALERT_COLOR](state, color) {
		state.alertColor = color
	},
	[SET_ALERT_POSITION](state, payload) {
		state.alertPosition = payload
	},
	[SET_ALERT_TEXT](state, text) {
		state.alertText = text
	},
	[SET_ALERT_COUNT](state, count) {
		state.alertCount = count
	},
	[RESET_ALERT](state, defaultState) {
		for (var prop in state) {
			state[prop] = defaultState[prop]
		}
	}
}

export default {
	actions,
	getters,
	state,
	mutations
}
