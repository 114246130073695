import {
	SET_SHOW_NAV_RIGHT_BUTTON,
	SET_NAV_ROUTE_TO,
	SET_NAV_TITLE,
	SET_SHOW_NAV_FOOTER,
	SET_SHOW_BACK,
	SET_SHOW_NAV_HEADER,
	SET_NAV_RIGHT_BUTTON_TEXT,
	SET_NAV_ONBOARDING,
	SET_NAV_INSTITUTION,
	SET_NAV_HEADER_PROMINENT,
	SET_NAV_EVENT,
	RESET_NAV
} from '../types'

const state = {
	title: '',
	showNavFooter: true,
	showNavHeader: false,
	showBack: false,
	showNavHeaderRightButton: false,
	navHeaderRightButtonText: '',
	routeTo: '',
	isOnboarding: false,
	institutions: [],
	event: null,
	prominent: false
}

const getters = {
	title: (state) => state.title,
	showNavFooter: (state) => state.showNavFooter,
	showNavHeader: (state) => state.showNavHeader,
	showBack: (state) => state.showBack,
	showNavHeaderRightButton: (state) => state.showNavHeaderRightButton,
	navHeaderRightButtonText: (state) => state.navHeaderRightButtonText,
	routeTo: (state) => state.routeTo,
	isOnboarding: (state) => state.isOnboarding,
	navInstitutions: (state) => state.institutions,
	navEvent: (state) => state.event,
	navProminent: (state) => state.prominent
}

const actions = {
	setNavOnboarding({ commit }, isOnboarding) {
		commit(SET_NAV_ONBOARDING, isOnboarding)
	},
	setNavInstitution({ commit }, institution) {
		commit(SET_NAV_INSTITUTION, institution)
	},
	setNavEvent({ commit }, event) {
		commit(SET_NAV_EVENT, event)
	},
	setNavTitle({ commit }, title) {
		commit(SET_NAV_TITLE, title)
	},
	setShowNavFooter({ commit }, payload) {
		commit(SET_SHOW_NAV_FOOTER, payload)
	},
	setShowNavHeader({ commit }, payload) {
		commit(SET_SHOW_NAV_HEADER, payload)
	},
	setShowBack({ commit }, payload) {
		commit(SET_SHOW_BACK, payload)
	},
	setShowNavHeaderRightButton({ commit }, payload) {
		commit(SET_SHOW_NAV_RIGHT_BUTTON, payload)
	},
	setNavHeaderRightButtonText({ commit }, text) {
		commit(SET_NAV_RIGHT_BUTTON_TEXT, text)
	},
	setNavRouteTo({ commit }, route) {
		commit(SET_NAV_ROUTE_TO, route)
	},
	setNavHeaderProminent({ commit }, payload) {
		commit(SET_NAV_HEADER_PROMINENT, payload)
	},
	resetNav({ commit }) {
		commit(RESET_NAV, {
			title: '',
			showNavFooter: true,
			showNavHeader: false,
			showBack: false,
			showNavHeaderRightButton: false,
			navHeaderRightButtonText: '',
			routeTo: '',
			isOnboarding: false,
			prominent: false,
			event: null,
			institutions: []
		})
	}
}

const mutations = {
	[SET_SHOW_NAV_RIGHT_BUTTON](state, payload) {
		state.showNavHeaderRightButton = payload
	},
	[SET_NAV_ROUTE_TO](state, route) {
		state.routeTo = route
	},
	[SET_NAV_TITLE](state, title) {
		state.title = title
	},
	[SET_NAV_HEADER_PROMINENT](state, value) {
		state.prominent = value
	},
	[SET_NAV_ONBOARDING](state, value) {
		state.isOnboarding = value
	},
	[SET_NAV_INSTITUTION](state, institution) {
		if (Array.isArray(institution)) {
			state.institutions = institution
		} else {
			state.institutions = [institution]
		}
	},
	[SET_NAV_EVENT](state, event) {
		state.event = event
		state.institutions = event && event.institutions
	},
	[SET_SHOW_NAV_FOOTER](state, payload) {
		state.showNavFooter = payload
	},
	[SET_SHOW_BACK](state, payload) {
		state.showBack = payload
	},
	[SET_SHOW_NAV_HEADER](state, payload) {
		state.showNavHeader = payload
	},
	[SET_NAV_RIGHT_BUTTON_TEXT](state, text) {
		state.navHeaderRightButtonText = text
	},
	[RESET_NAV](state, defaultState) {
		for (var prop in state) {
			state[prop] = defaultState[prop]
		}
	}
}

export default {
	actions,
	getters,
	state,
	mutations
}
