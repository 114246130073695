<template>
	<v-card
		class="card-error pa-4"
		@click="
			navigate({
				name: navigateTo
			})
		"
		v-show="error"
		v-bind="$attrs"
	>
		<v-row class="ma-0">
			<v-icon>fas fa-exclamation-triangle</v-icon>
			<p style="flex: 3">{{ error }}</p>
			<v-icon>fas fa-chevron-right</v-icon>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: 'error-card',
	props: ['navigateTo', 'error']
}
</script>

<style lang="scss" scoped>
.card-error {
	background-color: #ffe7ea !important;
	p,
	i {
		color: #db2137 !important;
	}
	p {
		margin: 0px 8px !important;
	}
	&:hover {
		cursor: pointer;
	}
}
</style>
