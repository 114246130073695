<template>
	<div class="step">
		<v-row class="ma-0" no-gutters>
			<v-col class="vcenter" style="margin-right: 10px !important">
				<v-divider class="divider"></v-divider>
			</v-col>
			<h3 class="number">{{ number }}</h3>
			<v-col class="vcenter" style="margin-left: 10px !important">
				<v-divider class="divider"></v-divider>
			</v-col>
		</v-row>
		<v-row class="vcenter">
			<v-spacer></v-spacer>
			<h3 style="margin-top: 0px" class="step-title">{{ title }}</h3>
			<v-spacer></v-spacer>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'onboarding-step',
	props: ['number', 'title']
}
</script>

<style lang="scss" scoped>
.step {
	margin-top: 16px !important;
	margin-bottom: 16px !important;
	.number {
		border: 1px solid $roundup-charcoal;
		border-radius: 1rem;
		width: 2em;
		height: 2em;
		line-height: 2em;
		text-align: center;
		color: white !important;
		background: $roundup-charcoal;
		font-size: 1rem !important;
	}
	.divider {
		color: $roundup-light-slate !important;
	}
	.step-title {
		font-size: 1rem !important;
		line-height: 1.5rem !important;
		color: $roundup-slate !important;
	}
	&.error-text {
		h3 {
			color: $roundup-red !important;
		}
		.number {
			color: $roundup-red !important;
			border: 1px solid $roundup-red;
			border-radius: 1rem;
			width: 1.5em;
			height: 1.5em;
			text-align: center;
		}
		hr {
			border-color: $roundup-red !important;
		}
	}
}
</style>
