import Vue from 'vue'
import Vuex from 'vuex'

import me from './modules/me'
import home from './modules/home'
import accountList from './modules/account.list'
import linkList from './modules/link.list'
import institutionList from './modules/institution.list'
import institutionDetail from './modules/institution.detail'
import notificationList from './modules/notification.list'
import roundupList from './modules/roundup.list'
import roundupDetail from './modules/roundup.detail'
import donationList from './modules/donations.list'
import donationDetail from './modules/donations.detail'
import transactionList from './modules/transaction.list'
import groupList from './modules/group.list'
import groupDetail from './modules/group.detail'
import algolia from './modules/algolia'
import searchList from './modules/search.list'
import taskList from './modules/task.list'
import nav from './modules/nav'
import alert from './modules/alert'
import oneTimeDonation from './modules/oneTimeDonation'
import scheduleDonation from './modules/donation.schedule'
import onboarding from './modules/onboarding'
import communityEvents from './modules/communityEvents.list'
import communityEventsDetail from './modules/communityEvents.detail'
import banners from './modules/banners.list'
import communityStories from './modules/communityStories.list'
import notificationPreferences from './modules/notificationPreferences.detail'
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		me,
		home,
		linkList,
		accountList,
		institutionList,
		institutionDetail,
		notificationList,
		roundupList,
		transactionList,
		groupDetail,
		groupList,
		algolia,
		searchList,
		roundupDetail,
		taskList,
		nav,
		alert,
		oneTimeDonation,
		donationList,
		donationDetail,
		scheduleDonation,
		onboarding,
		communityEvents,
		communityEventsDetail,
		banners,
		communityStories,
		notificationPreferences
	},
	strict: true
})
