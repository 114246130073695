import axios from '../../network/axios'
import { analytics } from '@/utilities/tracker'

import {
	SET_SCHEDULED_DONATION_AMOUNT,
	SET_SCHEDULED_DONATION_FEE_AMOUNT,
	SET_SCHEDULED_DONATION_FEE_ENABLED,
	SET_SCHEDULED_DONATION_FEE_DISABLED,
	SET_SCHEDULED_DONATION_TYPE,
	SET_SCHEDULED_DONATION_ROUNDUPS_ENABLED,
	SET_SCHEDULED_DONATION_INSTITUTION,
	SET_SCHEDULED_DONATION_CARD,
	RESET_SCHEDULED_DONATION,
	SET_SCHEDULE_DONATION_FAIL,
	SET_SCHEDULE_DONATION,
	SET_SCHEDULE_DONATION_SUCCESS,
	SET_SCHEDULED_DONATION_GROUP,
	SET_SCHEDULED_DONATION_EVENT,
	SET_SCHEDULED_USER_INSTITUTION
} from '../types'

const defaultState = {
	amount: 0,
	fee_amount: 0,
	fee_enabled: false,
	card: {
		last4: null,
		brand: null
	},
	institution: {
		name: null,
		uuid: null,
		username: null
	},
	group: null,
	event: null,
	type: 'RoundUp',
	roundupsEnabled: false,
	isLoading: false,
	error: null,
	amount_matched: null
}

const state = {
	amount: 50,
	fee_amount: 0,
	fee_enabled: true,
	card: {
		last4: null,
		brand: null
	},
	institution: {
		uuid: 'badb95e5-df82-499d-8f17-3a3e56864ab8'
	},
	group: null,
	event: null,
	type: 'RoundUp',
	roundupsEnabled: true,
	isLoading: false,
	error: null,
	amount_matched: null
}

const getters = {
	scheduleInstitution: (state) => state.institution,
	scheduleGroup: (state) => state.group,
	scheduleEvent: (state) => state.event,
	scheduleDonation: (state) => state
}

const actions = {
	setScheduledDonationAmount({ commit }, amount) {
		commit(SET_SCHEDULED_DONATION_AMOUNT, amount)
	},
	setScheduledDonationFeeAmount({ commit }, amount) {
		commit(SET_SCHEDULED_DONATION_FEE_AMOUNT, amount)
	},
	setScheduledDonationFeeStatus({ commit }, enabled) {
		if (enabled) {
			commit(SET_SCHEDULED_DONATION_FEE_ENABLED)
		} else {
			commit(SET_SCHEDULED_DONATION_FEE_DISABLED)
		}
	},
	setScheduledDonationType({ commit }, type) {
		commit(SET_SCHEDULED_DONATION_TYPE, type)
	},
	setScheduledDonationRoundups({ commit }, roundupsEnabled) {
		commit(SET_SCHEDULED_DONATION_ROUNDUPS_ENABLED, roundupsEnabled)
	},
	alertFirstInstitution({ dispatch, state }, institution) {
		return new Promise(() => {
			dispatch('setScheduleDonation', {
				is_pending: true,
				institution_uuid: state.institution.uuid,
				fixed_amount: state.amount * 100,
				roundups_enabled: state.roundupsEnabled,
				cover_stripe_fees: state.fee_enabled,
				...state
			})
			axios
				.post('/me/institution/mailchimp', { institution })
				.catch(() => null)
		})
	},
	setScheduledDonationInstitution({ commit }, institution) {
		commit(SET_SCHEDULED_DONATION_INSTITUTION, institution)
	},
	setScheduledDonationEvent({ commit }, e) {
		commit(SET_SCHEDULED_DONATION_EVENT, e)
	},
	setScheduledDonationCard({ commit }, card) {
		commit(SET_SCHEDULED_DONATION_CARD, card)
	},
	setScheduledDonationUI({ commit, dispatch }, ui) {
		commit(SET_SCHEDULED_USER_INSTITUTION, ui)
		dispatch('setScheduledDonationInstitution', {
			...ui.institution,
			...ui.institution.brand
		})
		dispatch(
			'setScheduledDonationAmount',
			(ui.fixed_amount || ui.minimum_donation) / 100
		)
		dispatch(
			'setScheduledDonationType',
			ui.roundups_enabled ? 'Roundup' : 'Monthly'
		)
	},
	resetScheduledDonation({ commit }) {
		commit(RESET_SCHEDULED_DONATION)
	},
	setScheduleDonation({ commit, state }, schedule) {
		const props = {
			institution_uuid: schedule.institution_uuid,
			institution_name: state.institution.name,
			roundups_enabled: schedule.roundups_enabled,
			donation_type: schedule.roundups_enabled ? 'round-up' : 'monthly',
			minimum_donation: schedule.roundups_enabled
				? schedule.minimum_donation
				: schedule.fixed_amount,
			is_pending: schedule.is_pending
		}

		analytics.track('recurring_donation_attempted', props)

		return new Promise((resolve, reject) => {
			commit(SET_SCHEDULE_DONATION)
			axios
				.post('/user/institutions/', schedule)
				.then(() => {
					analytics.track('recurring_donation_added', props)
					commit(SET_SCHEDULE_DONATION_SUCCESS)
					resolve()
				})
				.catch(({ response }) => {
					commit(SET_SCHEDULE_DONATION_FAIL, response.data)
					reject(response.data)
				})
		})
	},
	donateNow({ commit }, body) {
		return new Promise((resolve, reject) => {
			commit(SET_SCHEDULE_DONATION)
			axios
				.post('/user/donations/instant', body)
				.then(() => {
					analytics.track('one_time_donation', body)
					commit(SET_SCHEDULE_DONATION_SUCCESS)
					resolve()
				})
				.catch(({ response }) => {
					commit(SET_SCHEDULE_DONATION_FAIL, response.data)
					reject(response.data)
				})
		})
	},
	eventDonateNow({ commit }, body) {
		return new Promise((resolve, reject) => {
			commit(SET_SCHEDULE_DONATION)
			axios
				.post('/user/event/instant', body)
				.then(() => {
					if (window.analytics) {
						window.analytics.track('one_time_donation', body)
					}
					commit(SET_SCHEDULE_DONATION_SUCCESS)
					resolve()
				})
				.catch(({ response }) => {
					commit(SET_SCHEDULE_DONATION_FAIL, response.data)
					reject(response.data)
				})
		})
	},
	eventDonateNow({ commit }, { event_uuid, amount_cents }) {
		return new Promise((resolve, reject) => {
			commit(SET_SCHEDULE_DONATION)
			axios
				.post('/user/event/instant', {
					event_uuid,
					amount_cents
				})
				.then(() => {
					if (window.analytics) {
						window.analytics.track('one_time_donation', {
							event_uuid,
							amount_cents
						})
					}
					commit(SET_SCHEDULE_DONATION_SUCCESS)
					resolve()
				})
				.catch(({ response }) => {
					commit(SET_SCHEDULE_DONATION_FAIL, response.data)
					reject(response.data)
				})
		})
	}
}

const mutations = {
	[SET_SCHEDULE_DONATION](state) {
		state.isLoading = true
	},
	[SET_SCHEDULE_DONATION_SUCCESS](state) {
		state.isLoading = false
	},
	[SET_SCHEDULE_DONATION_FAIL](state, err) {
		state.isLoading = false
		state.err = err
	},
	[SET_SCHEDULED_DONATION_AMOUNT](state, amount) {
		state.amount = amount
	},
	[SET_SCHEDULED_DONATION_FEE_AMOUNT](state, amount) {
		state.fee_amount = amount
	},
	[SET_SCHEDULED_DONATION_FEE_ENABLED](state) {
		state.fee_enabled = true
	},
	[SET_SCHEDULED_DONATION_FEE_DISABLED](state) {
		state.fee_enabled = false
	},
	[SET_SCHEDULED_USER_INSTITUTION](state, ui) {
		state.ui = ui
	},
	[SET_SCHEDULED_DONATION_TYPE](state, type) {
		if (type === 'Roundup') {
			state.roundupsEnabled = true
		} else {
			state.roundupsEnabled = false
		}
		state.type = type
	},
	[SET_SCHEDULED_DONATION_CARD](state, card) {
		state.card = card
	},
	[SET_SCHEDULED_DONATION_INSTITUTION](state, institution) {
		state.institution = institution
	},
	[SET_SCHEDULED_DONATION_GROUP](state, group) {
		state.group = group
		state.institutions = group.institutions
	},

	[SET_SCHEDULED_DONATION_EVENT](state, event) {
		state.event = event
		state.institutions = event.institutions
	},
	[SET_SCHEDULED_DONATION_ROUNDUPS_ENABLED](state, roundsEnabled) {
		state.roundsEnabled = roundsEnabled
	},
	[RESET_SCHEDULED_DONATION](state) {
		state = { ...defaultState }
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
