<template>
	<div>
		<v-list-item two-line class="transaction-item">
			<v-list-item-content>
				<v-list-item-title
					style="
						color: #747a8d !important;
						font-weight: 600 !important;
					"
				>
					{{
						`${currencyFormat(transaction.amount)} ${
							transaction.name
						}`
					}}
				</v-list-item-title>
				<v-list-item-subtitle>{{
					formatDate(transaction.date)
				}}</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-content style="max-width: 75px !important">
				<v-list-item-title
					class="mb-0"
					style="
						color: #747a8d !important;
						font-weight: 600 !important;
						text-align: right !important;
					"
				>
					+{{ currencyFormat(transaction.roundUp) }}
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-divider></v-divider>
	</div>
</template>

<script>
import { formatDate, currencyFormat } from '@/utilities'

export default {
	name: 'transaction-list-item',
	props: ['transaction'],
	methods: {
		formatDate,
		currencyFormat
	}
}
</script>

<style lang="scss" scoped>
.transaction-list-item {
}
</style>
