const moment = require('moment')
import { Capacitor } from '@capacitor/core'

export const get = function (obj, key, empty) {
	return (
		key.split('.').reduce(function (o, x) {
			return typeof o === 'undefined' || o === null ? o : o[x]
		}, obj) || (isEmpty(empty) ? null : empty)
	)
}

export const set = function (obj, key, value) {
	if (!isEmpty(value)) {
		obj[key] = value
	}
}

export const isEmpty = function (val) {
	return typeof val === 'undefined' || val == null || val.length <= 0
}

export const has = function (obj, key) {
	return key.split('.').every(function (x) {
		if (typeof obj !== 'object' || obj === null || !(x in obj)) {
			return false
		}
		obj = obj[x]
		return true
	})
}

export const contains = function (objs, eqlFunc) {
	for (let value of objs) {
		if (eqlFunc(value)) {
			return true
		}
	}
	return false
}

export const indexOf = function (objs, func) {
	for (let i in objs) {
		if (func(objs[i])) {
			return i
		}
	}
	return null
}

export const split = function (str, char, func) {
	if (isEmpty(str)) return []
	func = func || ((val) => val)
	let objs = str.split(char)
	for (let i in objs) {
		objs[i] = func(objs[i])
	}
	return objs
}

export const pluck = function (obj, keys) {
	if (keys == null) return {}
	return keys.reduce(function (newObj, key) {
		newObj[key] = obj[key]
		return newObj
	}, {})
}

export const prune = function (obj, func) {
	func = func || isEmpty
	for (const key in obj) {
		if (func(obj[key])) {
			delete obj[key]
		}
	}
	return obj
}

export const first = function (array) {
	return !isEmpty(array) ? (array.length > 0 ? array[0] : null) : null
}

export const last = function (array) {
	return array[array.length - 1]
}

export const comb = function () {
	return [...arguments].join('')
}

export const loadScript = function (src) {
	return new Promise(function (resolve, reject) {
		if (document.querySelector('script[src="' + src + '"]')) {
			resolve()
			return
		}
		const el = document.createElement('script')

		el.type = 'text/javascript'
		el.async = true
		el.src = src

		el.addEventListener('load', resolve)
		el.addEventListener('error', reject)
		el.addEventListener('abort', reject)

		document.head.appendChild(el)
	})
}

export const simpleDateFormat = function (date) {
	var monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]

	const localDate = new Date(date.toString() + ' UTC')

	const day = localDate.getDate()
	const monthIndex = localDate.getMonth()
	const year = localDate.getFullYear()

	return monthNames[monthIndex] + ' ' + day + ', ' + year
}

export const simpleDateFormatMoment = function (date, yearOnly = null) {
	var monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]

	const day = date.date()
	const monthIndex = date.month()
	const year = date.year()

	if (yearOnly) {
		return year
	}
	return monthNames[monthIndex] + ' ' + day + ', ' + year
}

export const formatDate = (date) => {
	return moment(date).format('MMM DD, YYYY')
}

export const monthDiff = function (date) {
	var months
	const d2 = new Date(date)
	const d1 = new Date()

	months = (d2.getFullYear() - d1.getFullYear()) * 12
	months -= d1.getMonth()
	months += d2.getMonth()

	return months <= 0 ? 0 : months
}

export const isMobileScreenSize = function () {
	return document.documentElement.clientWidth <= 480
}

export const debounce = function (func, wait, immediate) {
	var timeout, args, context, timestamp, result
	if (wait == null) wait = 100

	function later() {
		var last = Date.now() - timestamp

		if (last < wait && last >= 0) {
			timeout = setTimeout(later, wait - last)
		} else {
			timeout = null
			if (!immediate) {
				result = func.apply(context, args)
				context = args = null
			}
		}
	}

	var debounced = function () {
		context = this
		args = arguments
		timestamp = Date.now()
		var callNow = immediate && !timeout
		if (!timeout) timeout = setTimeout(later, wait)
		if (callNow) {
			result = func.apply(context, args)
			context = args = null
		}
		return result
	}

	debounced.clear = function () {
		if (timeout) {
			clearTimeout(timeout)
			timeout = null
		}
	}

	debounced.flush = function () {
		if (timeout) {
			result = func.apply(context, args)
			context = args = null

			clearTimeout(timeout)
			timeout = null
		}
	}

	return debounced
}

export const getUrlParams = function (url) {
	var queryString = url ? url.split('?')[1] : window.location.search.slice(1)
	var obj = {}

	if (queryString) {
		queryString = queryString.split('#')[0]
		var arr = queryString.split('&')

		for (var i = 0; i < arr.length; i++) {
			var a = arr[i].split('=')
			var paramNum
			var paramName = a[0].replace(/\[\d*\]/, function (v) {
				paramNum = v.slice(1, -1)
				return ''
			})
			var paramValue = typeof a[1] === 'undefined' ? true : a[1]

			paramValue = paramValue.replace(/%20/g, ' ')
			try {
				paramName = paramName.toLowerCase()
			} catch {
				null
			}
			try {
				paramValue = paramValue.toLowerCase()
			} catch {
				null
			}

			if (obj[paramName]) {
				if (typeof obj[paramName] === 'string') {
					obj[paramName] = [obj[paramName]]
				}
				if (typeof paramNum === 'undefined') {
					obj[paramName].push(paramValue)
				} else {
					obj[paramName][paramNum] = paramValue
				}
			} else {
				obj[paramName] = paramValue
			}
		}
	}
	return obj
}

export const isMobile = () => {
	return (
		Capacitor.getPlatform() === 'android' ||
		Capacitor.getPlatform() === 'ios'
	)
}

export const isIOS = () => {
	return Capacitor.getPlatform() === 'ios'
}

export const isAndroid = () => {
	return Capacitor.getPlatform() === 'android'
}

export const currencyFormat = (val) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2
	})

	return formatter.format(val)
}

export const getDaysArray = function (start, end) {
	for (
		var arr = [], dt = new Date(start);
		dt <= end;
		dt.setDate(dt.getDate() + 1)
	) {
		arr.push(new Date(dt))
	}
	return arr
}

export const getCreditCardIconNames = function (brand) {
	if (!brand) {
		return 'fa fa-credit-card'
	}
	switch (brand.toLowerCase()) {
		case 'visa':
			return 'fab fa-cc-visa'
		case 'mastercard':
			return 'fab fa-cc-mastercard'
		case 'discover':
			return 'fab fa-cc-discover'
		case 'paypal':
			return 'fab fa-cc-paypal'
		case 'apple':
			return 'fab fa-cc-apple-pay'
		case "diner's club":
			return 'fab fa-cc-diners-club'
		case 'american express':
			return 'fab fa-cc-amex'
		default:
			return 'fa fa-credit-card'
	}
}

export const commaSplice = (list) => {
	var str = ''
	if (list.length === 1) {
		str = list.join('')
	} else if (list.length === 2) {
		str = list.join(' and ')
	} else if (list.length > 2) {
		str = list.slice(0, -1).join(', ') + 'and ' + list.slice(-1)
	}
	return str
}

export const loadImg = (url, fallback) => {
	const img = new Image()
	img.src = url

	if (img.complete) {
		return url
	} else {
		img.onload = () => {
			return url
		}
		img.onerror = () => {
			return fallback
		}
	}
}

// https validator
export const withHttp = (url) =>
	!/^https?:\/\//i.test(url) ? `http://${url}` : url
