<template>
	<v-container class="nav-footer" fluid>
		<v-row>
			<v-col
				:class="{
					active: isActive('home')
				}"
				class="btn"
				@click="navigate({ name: 'home' }, 'fade')"
			>
				<v-icon class="nav-footer-icon">far fa-compass</v-icon>
				<p class="mb-0" style="color: inherit">Home</p>
			</v-col>
			<v-col
				:class="{
					active: isActive('discover')
				}"
				class="btn"
				@click="navigate({ name: 'discover' }, 'fade')"
			>
				<v-icon class="nav-footer-icon">fa fa-search</v-icon>
				<p class="mb-0">Discover</p>
			</v-col>
			<v-col
				:class="{
					active: isActive('profile')
				}"
				class="btn"
				@click="navigate({ name: 'profile' }, 'fade')"
			>
				<v-icon class="nav-footer-icon">fa fa-user-o</v-icon>
				<p class="mb-0">Profile</p>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'nav-footer',

	props: [],
	data: () => ({}),
	methods: {
		isActive(name) {
			return name === this.$route.name
		}
	}
}
</script>

<style lang="scss" scoped>
.nav-footer {
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	background: #fff;

	&-icon {
		margin-bottom: 5px;
		color: inherit !important;
	}
	.active {
		color: $roundup-navy !important;
	}
	.btn {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	p {
		color: inherit !important;
	}

	@media (max-width: 480px) {
		p {
			font-size: 14px !important;
			font-weight: $font-regular !important;
		}
	}
}
</style>
