import axios from '../../network/axios'
import { simpleDateFormat, simpleDateFormatMoment } from '../../utilities'
import { TRANS_LIST, TRANS_LIST_REQUEST } from '../types'
import moment from 'moment-timezone'

const state = {
	isFetching: false,
	items: [],
	totals: {
		next: 0,
		all: 0
	},
	graph: []
}

const getters = {
	transactions: (state) => state.items,
	totals: (state) => state.totals,
	grouped: (state) => {
		var groups = []

		// Not converting to timezone since plaid has no timezone details
		for (const item of state.items) {
			let newDate = moment.utc(item.datetime)
			item.newDateTime = newDate
			var dateGroup = null
			for (const group of groups) {
				if (simpleDateFormatMoment(newDate, true) === group.date) {
					dateGroup = group
				}
			}
			if (dateGroup === null) {
				dateGroup = {
					date: simpleDateFormatMoment(newDate, true),
					items: []
				}
				groups.push(dateGroup)
			}
			dateGroup.items.push(item)
		}
		return groups
	},
	recentTransactions: (state) =>
		state.items.slice(0, 5).sort((a, b) => a.id - b.id),
	transactionsFetching: (state) => state.isFetching
}

const actions = {
	getTransactions({ commit }) {
		return new Promise((resolve) => {
			commit(TRANS_LIST_REQUEST)
			axios.get('/transactions').then(({ data }) => {
				commit(TRANS_LIST, data)
				resolve()
			})
		})
	}
}

const mutations = {
	[TRANS_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[TRANS_LIST](state, data) {
		state.isFetching = false
		state.items = data.transactions
		state.totals = data.totals
		state.graph = data.graph
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
