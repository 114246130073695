<template>
	<v-container
		class="roundup-container"
		:class="[
			(isOnboarding || navProminent) && 'prominent',
			!showNavHeader && 'no-header'
		]"
		:style="{ 'padding-top': paddingTop }"
	>
		<slot />
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { isIOS } from '@/utilities'

export default {
	name: 'roundup-container',
	computed: {
		...mapGetters(['isOnboarding', 'showNavHeader', 'navProminent']),
		paddingTop() {
			let padding = 56
			if (this.navProminent || this.isOnboarding) {
				return null
			}
			if (isIOS()) {
				padding = 80
			}
			return `${padding}px !important`
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/elements.scss';
.roundup-container {
	flex: 1;
	background: $white;
	padding: 72px 16px $footer-height 16px !important;
	overflow-y: auto;
	box-shadow: none !important;
	&.prominent {
		padding-top: 130px !important;
	}
	&.no-header {
		padding-top: 0px !important;
	}
}
</style>
