<template>
	<OnboardingHeader
		class="nav-header"
		:institution="cause"
		@backClick="handleOnboardingBack"
		v-if="nav.isOnboarding"
	/>
	<nav-header
		v-else
		v-show="nav.showNavHeader"
		@navButtonClick="$emit('navButtonClick')"
	/>
</template>

<script>
import NavHeader from './NavHeader.vue'
import { OnboardingHeader } from '@/ui'
import { mapState } from 'vuex'
export default {
	name: 'nav-header-controller',
	components: { NavHeader, OnboardingHeader },
	computed: {
		...mapState({
			nav: ({ nav }) => nav
		}),
		cause() {
			if (this.nav.event) {
				return {
					name: this.nav.event.name,
					logo_url: this.nav.event.icon
				}
			}
			return this.nav.institutions[0]
		}
	},
	methods: {
		handleOnboardingBack() {
			if (this.nav.event) {
				this.navigate(
					{
						name: 'community',
						params: { uuid: this.nav.event.uuid }
					},
					'back'
				)
			} else if (this.$route.params.backRoute) {
				this.navigate({
					name: this.$route.params.backRoute
				})
			} else if (this.nav.institution.uuid) {
				this.navigate(
					{
						name: 'discover-details',
						params: { uuid: this.nav.institution.uuid }
					},
					'back'
				)
			} else {
				this.navigate({
					name: 'thank-you'
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.nav-header {
	background: $roundup-primary !important;
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	z-index: 10;
	.nav-container {
		height: 56px;
	}
	.nav-left-button-wrapper {
		justify-content: flex-start;
		margin-left: 24px;
		margin-bottom: 3px;
		flex: 1;
		.nav-left-button {
			font-size: 18px;
			color: white;
		}
	}
	.nav-center-button-wrapper {
		flex: 2;
		margin-left: 16px;
	}
	.nav-right-button-wrapper {
		flex: 1;
		justify-content: flex-end;
		padding-right: 24px;
	}
	.col {
		display: flex;
	}
	.v-icon::after {
		height: 20px;
		width: 20px;
		left: -3px;
		top: 0px;
	}
}
</style>
