import axios from '../../network/axios'
import { NOTIF_PREF_DETAIL } from '../types'

const state = {
	preferences: {}
}

const getters = {
	push: (state) =>
		state.preferences.push === undefined ? true : state.preferences.push
}

const actions = {
	getNotificationPreferences({ commit, dispatch }) {
		return new Promise((resolve) => {
			axios
				.get('/user/notificationPreferences')
				.then(({ data }) => {
					commit(NOTIF_PREF_DETAIL, data)
					resolve(data)
				})
				.then(() => {
					dispatch('getCurrentUser')
				})
		})
	},
	createNotificationPreferences({ dispatch, commit }, body) {
		return new Promise((resolve) => {
			axios
				.post('/user/notificationPreferences/', { push: body.push })
				.then(() => {
					dispatch('getNotificationPreferences')
				})
				.then(() => resolve())
				.catch((e) => console.log(e))
		})
	},
	updateNotificationPreferences({ dispatch, commit }, body) {
		return new Promise((resolve) => {
			axios
				.put('/user/notificationPreferences/', { push: body.push })
				.then(() => {
					analytics.track('preferences_updated', {
						preference_type: 'marketing_push_notifications',
						preference_value: body.push
					})
					dispatch('getNotificationPreferences')
				})
				.then(() => resolve())
				.catch((e) => console.log(e))
		})
	}
}

const mutations = {
	[NOTIF_PREF_DETAIL](state, data) {
		state.preferences = { push: state.preferences.push, ...data }
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
