<template>
	<v-row
		class="mt-6"
		:class="[
			backgroundColor
				? `background-${backgroundColor}`
				: 'background-fade'
		]"
		align-content="space-around"
		v-if="!vertical"
	>
		<v-col align="center">
			<ShareNetwork
				network="facebook"
				:url="url"
				:title="title"
				:description="body"
				:quote="body"
				hashtags="roundupapp,charity,pocketchange,doinggood"
			>
				<v-btn fab class="share-btn" small>
					<v-icon class="icon" color="#3770FE"
						>fab fa-facebook-f</v-icon
					>
				</v-btn>
			</ShareNetwork>
		</v-col>
		<v-col align="center">
			<ShareNetwork
				network="twitter"
				:url="url"
				:title="title"
				:description="body"
				hashtags="roundupapp,charity,pocketchange,doinggood"
			>
				<v-btn fab class="share-btn" small>
					<v-icon class="icon" color="#3770FE">fab fa-twitter</v-icon>
				</v-btn>
			</ShareNetwork>
		</v-col>
		<v-col align="center">
			<ShareNetwork
				network="sms"
				:url="url"
				:title="title"
				:description="body"
				hashtags="roundupapp,charity,pocketchange,doinggood"
			>
				<v-btn fab class="share-btn" small>
					<v-icon class="icon" color="#3770FE">fas fa-comment</v-icon>
				</v-btn>
			</ShareNetwork>
		</v-col>
		<v-col align="center">
			<ShareNetwork
				network="email"
				:url="url"
				:title="title"
				:description="body"
				hashtags="roundupapp,charity,pocketchange,doinggood"
			>
				<v-btn fab class="share-btn" small>
					<v-icon class="icon" color="#3770FE"
						>fas fa-envelope</v-icon
					>
				</v-btn>
			</ShareNetwork>
		</v-col>
	</v-row>
	<v-row v-else>
		<v-col cols="2"></v-col>
		<v-col class="mt-6" cols="8">
			<v-row align-content="space-around" class="ma-0 mt-2">
				<div class="share-btn">
					<ShareNetwork
						network="facebook"
						:url="url"
						:title="title"
						:description="body"
						:quote="body"
						hashtags="roundupapp,charity,pocketchange,doinggood"
					>
						<v-btn fab class="share-btn">
							<v-icon x-large color="#3770FE"
								>fab fa-facebook-square</v-icon
							>
						</v-btn>
						Share with Facebook
					</ShareNetwork>
				</div>
			</v-row>
			<v-row align-content="space-around" class="mt-2">
				<div class="share-btn">
					<ShareNetwork
						network="twitter"
						:url="url"
						:title="title"
						:description="body"
						hashtags="roundupapp,charity,pocketchange,doinggood"
					>
						<v-btn fab class="share-btn">
							<v-icon x-large color="#3770FE"
								>fab fa-twitter-square</v-icon
							>
						</v-btn>
						Share with Twitter
					</ShareNetwork>
				</div>
			</v-row>
			<v-row align-content="space-around" class="mt-2">
				<div class="share-btn">
					<ShareNetwork
						network="sms"
						:url="url"
						:title="title"
						:description="body"
						hashtags="roundupapp,charity,pocketchange,doinggood"
					>
						<v-btn fab class="share-btn">
							<v-icon x-large color="#3770FE"
								>fas fa-comment</v-icon
							>
						</v-btn>
						Share via text message
					</ShareNetwork>
				</div>
			</v-row>
			<v-row align-content="space-around" class="mt-2">
				<div class="share-btn">
					<ShareNetwork
						network="email"
						:url="url"
						:title="title"
						:description="body"
						hashtags="roundupapp,charity,pocketchange,doinggood"
					>
						<v-btn fab class="share-btn">
							<v-icon x-large color="#3770FE"
								>fas fa-envelope</v-icon
							>
						</v-btn>
						Share via email
					</ShareNetwork>
				</div>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'share-bar',
	props: {
		vertical: { type: Boolean, default: false },
		url: String,
		title: String,
		body: String,
		backgroundColor: String
	},
	computed: {
		...mapGetters(['user'])
	}
}
</script>

<style lang="scss" scoped>
a {
	text-decoration: none;
}
.icon {
	color: white;
}
.mt-2 {
	margin-top: 8px !important;
}
.background-white {
	background: white !important;
}
.background-white::v-deep .share-btn {
	background: $roundup-bone !important;
	::v-deep i {
		color: $roundup-cerulean !important;
	}
}
</style>
