import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_REMOTE_URL
axios.defaults.headers.common = {
	'X-Requested-With': 'XMLHttpRequest',
	'X-Roundup-Type': process.env.VUE_APP_TYPE
}

axios.interceptors.request.use(function (config) {
	if (!config.headers) {
		config.headers = {} // Create the header object if needed.
	}
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('token')
	config.headers['Authorization'] = token ? `Bearer ${token}` : null

	return config
})

export default axios
