<template>
	<p>
		Your donation will be distributed to the listed organizations either via
		Stripe directly to the nonprofits listed above or our donor-advised fund
		account. As described in
		<a href="https://roundupapp.com/terms" target="_blank"
			>our terms of use,</a
		>
		Flourish Change, Inc. does not hold or retain any portion of your
		donation, but only acts as a payment processor for purposes of remitting
		your donation. No goods or services were provided to you in exchange for
		this donation. Your donation is tax-deductible to the extent allowable
		by law.
	</p>
</template>

<script>
export default {
	name: 'donation-disclaimer'
}
</script>

<style lang="scss" scoped>
p {
	font-size: 0.75rem !important;
	color: $roundup-slate !important;
	a {
		font-size: inherit !important;
	}
}
</style>
