import { AppsFlyer } from 'appsflyer-capacitor-plugin'
import store from '@/store'

export const analytics = {
	track(event, params = {}, trackAF = false) {
		try {
			if (window.analytics) {
				params['device_type'] = process.env.VUE_APP_TYPE
				window.analytics.track(event, params)
			}
			if (trackAF && process.env.VUE_APP_TYPE !== 'web') {
				const eventNameMap = {
					'login-success': 'af_login',
					login_with_google: 'af_login',
					register: 'af_complete_registration',
					user_sign_up: 'af_complete_registration'
				}

				const data = {
					eventName: eventNameMap[event]
				}

				AppsFlyer.logEvent(data)
					.then((r) => {})
					.catch((err) => console.log('logEvent err ~~>' + err))
			}
		} catch (e) {
			console.log(e)
		}
	},
	identify() {
		try {
			if (window.analytics) {
				window.analytics.identify(store.getters.user.id, {
					email: store.getters.user.email
				})
			}
		} catch (e) {
			console.log(e)
		}
	}
}

export const facebook = {
	boot(code) {
		window.fbq('init', code)
	},
	track(event, params = {}) {
		window.fbq('track', event, params)
	}
}
