import { App } from '@capacitor/app'
import _ from 'lodash'

import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { getUrlParams } from '../utilities'
import routes from './async-routes'
import publicRedirects from './public-private'

Vue.use(Router)

const router = new Router({
	mode: process.env.VUE_APP_TYPE === 'web' ? 'history' : 'hash',
	routes: [
		/* ***** PUBLIC ROUTES ****** */

		{
			path: '/',
			name: 'launch',
			meta: { guest: true },
			redirect: (from) => {
				return {
					name: 'give',
					params: { props: from.props, query: from.query }
				}
			}
		},
		{
			path: '/login/:redirect?',
			name: 'login',
			component: routes.login,
			meta: { guest: true },
			props: true
		},
		{
			path: '/login/forgot-password',
			name: 'forgot-password',
			component: routes.forgotPassword,
			meta: { guest: true }
		},
		{
			path: '/reset-password/:uuid',
			name: 'reset-password',
			component: routes.resetPassword,
			meta: { guest: true },
			props: true
		},
		{
			path: '/Authenticate/:token/:path',
			name: 'authenticate',
			component: routes.handleTokenAuth,
			meta: { tokenAuth: true, guest: true },
			props: true
		},
		{
			path: '/p/discover',
			name: 'public-discover',
			component: routes.discover,
			meta: { guest: true }
		},
		{
			path: '/p/discover/categories',
			name: 'public-discover-categories',
			component: routes.categories,
			meta: { guest: true }
		},
		{
			path: '/p/discover/results',
			name: 'public-discover-results',
			component: routes.results,
			meta: { guest: true }
		},
		{
			path: '/p/discover/details/:uuid',
			name: 'public-discover-details',
			component: routes.details,
			meta: { guest: true }
		},
		{
			path: '/thank-you',
			name: 'thank-you',
			component: routes.thankYou,
			meta: { guest: true }
		},
		{
			path: '/p/community/events/detail',
			name: 'community-events-detail',
			component: routes.communityEventsDetail,
			meta: { guest: true }
		},
		{
			path: '/p/community',
			name: 'community',
			component: routes.community,
			meta: { guest: true }
		},
		/* ***** PRIVATE ROUTES ****** */
		{
			path: '/give',
			name: 'give',
			component: routes.give
		},
		{
			path: '/give/nonprofits/details/:uuid',
			name: 'give-institution-details',
			component: routes.institutionDetails,
			props: true
		},
		{
			path: '/donate',
			name: 'one-time-donate',
			component: routes.oneTimeGift
		},

		{
			path: '/my-causes',
			name: 'my-causes',
			component: routes.myCauses
		},
		{
			path: '/my-causes/schedule-donation',
			name: 'my-causes-schedule-donation',
			component: routes.confirmDonation,
			props: true
		},
		{
			path: '/profile',
			name: 'profile',
			component: routes.profile
		},
		{
			path: '/profile/edit',
			name: 'profile-edit',
			component: routes.profileEdit
		},
		{
			path: '/profile/payment-options',
			name: 'profile-payment-options',
			component: routes.paymentOptions
		},

		{
			path: '/profile/payment-options/edit-payment-method',
			name: 'profile-payment-options-edit-payment-method',
			component: routes.paymentOptionsEditCardView,
			props: true
		},

		{
			path: '/profile/settings',
			name: 'profile-settings',
			component: routes.profileSettings
		},
		{
			path: '/profile/settings/reset-password',
			name: 'profile-settings-reset-password',
			component: routes.profileSettingsResetPassword
		},
		{
			path: '/profile/settings/notification-preferences',
			name: 'profile-settings-notification-preferences',
			component: routes.profileSettingsNotificationPreferences
		},
		{
			path: '/profile/support',
			name: 'profile-support',
			component: routes.profileSupport
		},
		{
			path: '/profile/support/contact-us',
			name: 'profile-support-contact-us',
			component: routes.profileSupportContactUs
		},
		{
			path: '/profile/donations',
			name: 'profile-donation-settings',
			component: routes.donationSettingsList
		},
		{
			path: '/profile/donations/preferences',
			name: 'profile-donation-preferences',
			component: routes.donationPreferences
		},
		{
			path: '/profile/donations/linkedAccounts',
			name: 'profile-linked-accounts',
			component: routes.roundUpAccounts,
			props: true
		},
		{
			path: '/profile/donations/summary',
			name: 'profile-donation-summary',
			component: routes.donationSummary
		},
		{
			path: '/profile/donations/summary/:uuid',
			name: 'profile-donation-summary-details',
			component: routes.donationSummaryDetail
		},
		{
			path: '/profile/donations/failed',
			name: 'profile-failed-payouts',
			component: routes.donationFailedSummary
		},
		{
			path: '/profile/donations/transactions',
			name: 'profile-transaction-summary',
			component: routes.transactionSummary
		},
		{
			path: '/discover',
			name: 'discover',
			component: routes.discover
		},
		{
			path: '/discover/categories',
			name: 'discover-categories',
			component: routes.categories
		},
		{
			path: '/discover/results',
			name: 'discover-results',
			component: routes.results
		},
		{
			path: '/discover/details/:uuid',
			name: 'discover-details',
			component: routes.details
		},
		{
			path: '/community/events/detail',
			name: 'community-events-detail',
			redirect: '/p/community/events/detail'
		},
		{
			path: '/community',
			name: 'community',
			redirect: '/p/community'
		},
		// {
		// 	path: '/discover/details/:uuid/donate',
		// 	name: 'discover-donate',
		// 	component: routes.donationChoice
		// },

		// REDIRECTS FROM OLD APP
		{
			path: '/Register',
			name: 'register',
			redirect: '/p/discover',
			meta: { guest: true },
			props: true
		},
		{
			path: '/Register/email',
			redirect: '/p/discover',
			meta: { guest: true }
		},
		{
			path: '/Register/:uuid',
			redirect: '/p/discover/details/:uuid',
			meta: { guest: true, ignoreAuth: true },
			props: true
		},
		{
			path: '/Register/group/:uuid',
			name: 'register-group',
			redirect: '/p/discover',
			meta: { guest: true, ignoreAuth: true },
			props: true
		},
		{
			path: '/Donate/Success/:uuid',
			redirect: '/p/discover',
			meta: { guest: true },
			props: true
		},
		{
			path: '/Donate/:uuid',
			redirect: (from) => {
				return {
					name: 'public-discover-details',
					query: { donate: true },
					params: { uuid: from.params.uuid }
				}
			},
			meta: { guest: true, ignoreAuth: true },
			props: true
		},
		// {
		// 	path: '/SetupPassword/:uuid',
		// 	name: 'setup-password',
		// 	component: () => import('@/components/Auth/SetupPasswordView'),
		// 	meta: { guest: true },
		// 	props: true
		// },
		// {
		// 	path: '/RedirectToApp',
		// 	name: 'redirect',
		// 	component: () => import('@/components/Auth/RedirectToApp'),
		// 	meta: { guest: true }
		// },
		{
			path: '/logout',
			name: 'logout'
		},
		{
			path: '/Institutions',
			redirect: '/p/discover',
			props: true,
			meta: { guest: true }
		},
		{
			path: '/MinDonation',
			redirect: '/discover',
			props: true
		},
		{
			path: '/WholeDollar',
			redirect: '/give',
			props: true
		},
		{
			path: '/Accounts',
			redirect: 'profile-linked-accounts',
			props: true
		},
		{
			path: '/PreCard',
			redirect: { name: 'profile-payment-options' }
		},
		{
			path: '/Card',
			redirect: { name: 'profile-payment-options' }
		},
		{
			path: '/PrePlaid',
			redirect: { name: 'profile-linked-accounts' }
		},
		{
			path: '/Home',
			redirect: '/give'
		},
		{
			path: '/Donations',
			name: { name: 'profile-donation-summary' }
		},
		{
			path: '/Designations',
			redirect: '/discover'
		},
		{
			path: '/DonateNow',
			redirect: { name: 'one-time-donate' }
		},
		{
			path: '/Settings',
			redirect: { name: 'profile' }
		},
		{
			path: '/Settings/MinMax',
			redirect: { name: 'profile' }
		},
		{
			path: '/Settings/Legal',
			redirect: { name: 'profile-support' }
		},
		{
			path: '/Settings/ChangePassword',
			redirect: { name: 'profile-settings-reset-password' }
		},
		{
			path: '/Settings/UpdateUser',
			redirect: { name: 'profile-edit' }
		},
		{
			path: '/Settings/AppDetails',
			redirect: { name: 'profile' }
		},
		{
			path: '/Settings/ChangeInstitution',
			redirect: { name: 'discover' }
		},
		// HAS TO BE AT THE BOTTOM
		{
			path: '*',
			name: '404',
			component: routes.pageNotFound,
			meta: { guest: true }
		}
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach((to, from, next) => {
	if (
		// see if there are UTM parameters
		Object.keys(from.query).length > 0 &&
		// see if they're ACTUALLY UTM PARAMETERS
		Object.keys(from.query).join('').includes('utm') &&
		// confirm that you are moving to a guest state
		to.meta.guest === from.meta.guest &&
		// confirm that you are remaining a guest, if authed, we only care about the landing page, or the intended page
		(to.meta.guest || from.name === 'login') &&
		// make sure we havent already added the parameters
		!_.isEqual(from.query, to.query)
	) {
		next({ path: to.path, query: from.query })
		return
	}

	//remove frame styling for group register page
	if (from.name === 'register-group') {
		var parent = document.getElementById('parent')
		parent.style.maxWidth = '450px'
		parent.style.position = 'relative'
	}

	// Make sure you let tokened users log in
	if (to.meta.tokenAuth) {
		next()
		return
	}

	// Make sure you always let people login
	if (to.meta.guest && !store.state.me.isLoggedIn) {
		if (process.env.VUE_APP_INSTITUTION_UUID && to.name === 'launch') {
			next({
				name: 'public-discover-details',
				params: { uuid: process.env.VUE_APP_INSTITUTION_UUID },
				query: from.query
			})
			return
		}
		next()
		return
	}

	if (to.name === 'logout') {
		localStorage.clear()
		store.dispatch('logout').then(() => {
			next({ name: 'public-discover' })
		})
		next()
		return
	}

	window.analytics.page(to.fullPath, {
		referrer: from.path,
		url: to.fullPath
	})

	// Ensure we have a user object before we start routing
	store.dispatch('checkUserLoad').then(
		() => {
			if (Object.keys(publicRedirects).includes(to.name)) {
				next({
					name: publicRedirects[to.name],
					params: to.params,
					meta: to.meta,
					query: to.query
				})
				return
			}

			//if landing on public page, stay there
			if (to.meta.ignoreAuth) {
				next()
				return
			}

			// If you are trying to go to the login screen, send you to give/home
			// if (
			// 	to.meta.guest &&
			// 	store.state.me.isLoggedIn &&
			// 	to.name !== 'redirect'
			// ) {
			// 	next({ name: 'give' })
			// 	return
			// }

			// If everything is still golden, lets keep going
			next()
		},
		() => {
			if (to.name === 'give') {
				next({ name: 'public-discover', query: to.query })
				return
			}

			next({
				name: 'login',
				params: { redirect: to.name },
				query: to.query
			})
		}
	)
})

App.addListener('appUrlOpen', function (data) {
	// Example url: https://roundup.app/app/tabs/tabs2
	// slug = /tabs/tabs2
	const redirectUri = data.url.toLowerCase().split('roundupapp://').pop()

	if (redirectUri && redirectUri.length > 0) {
		const splitUri = redirectUri.split('?')

		const routeName = splitUri[0]

		let params = {
			name: routeName
		}

		// if the uri had queries, pass to route
		if (splitUri[1]) {
			params.query = getUrlParams(redirectUri)
		}

		router.push(params)
	} else {
		router.push({ name: 'give' })
	}
})

export default router
