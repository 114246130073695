<template>
	<div>
		<v-list-item
			:two-line="!donation.gifted_donation"
			:three-line="!!donation.gifted_donation"
			class="donation-item"
			:class="[allFailed && 'failed', pending && 'pending']"
			@click="navigateToDonation(donation)"
			:style="
				donation.amount_matched && { paddingBottom: '16px !important' }
			"
		>
			<v-list-item-content>
				<v-row class="pr-4 pl-4">
					<v-progress-circular
						v-if="pending"
						indeterminate
						color="primary"
					></v-progress-circular>
					<v-icon
						v-if="donation.had_error && !donation.child_id"
						color="#DB2137"
						class="mr-4"
						>fas fa-exclamation-triangle</v-icon
					>
					<div :class="[pending && 'ml-4']">
						<v-list-item-title>{{
							formatDate(donation.processed_date)
						}}</v-list-item-title>
						<v-list-item-subtitle>
							{{ getSubtitle(donation.institutions) }}
						</v-list-item-subtitle>
						<v-list-item-subtitle
							v-if="donation.gifted_donation"
							style="margin-top: 10px"
						>
							<v-icon size="14" color="#2553cd"
								>fas fa-dove</v-icon
							><span
								style="
									color: #2553cd;
									font-size: 14px !important;
									margin-left: 5px;
								"
								>Tribute Gift for
								{{ donation.gifted_donation.honoree_full_name }}
							</span>
						</v-list-item-subtitle>
					</div>
					<v-spacer></v-spacer>
					<p class="money mb-0">
						{{
							currencyFormat(
								donation.total_amount_captured
									? donation.total_amount_captured / 100
									: 0
							)
						}}
					</p>
				</v-row>
			</v-list-item-content>
			<div class="matched-banner" v-if="donation.amount_matched">
				<v-icon size="16px" color="#2553cd" style="margin-right: 8px">
					fas fa-gift
				</v-icon>
				<p>{{ currencyFormat(donation.amount_matched / 100) }} Match</p>
			</div>
		</v-list-item>
		<v-divider></v-divider>
		<v-dialog v-model="showPendingModal" max-width="290" :attach="this.$el">
			<v-card>
				<v-card-title class="text-h5"> Pending Donation </v-card-title>

				<v-card-text>
					Please wait until we finish processing your gift before
					viewing the details. This can take up to 30 seconds. Please
					refresh your page if you have been waiting longer than 30
					seconds.
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<Roundup-Button
						color="clear"
						@click="showPendingModal = false"
					>
						Dismiss
					</Roundup-Button>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate, currencyFormat } from '@/utilities'

export default {
	name: 'donation-list-item',
	props: ['donation'],
	data: () => {
		return {
			showPendingModal: false
		}
	},
	computed: {
		allFailed() {
			const payouts = this.donation ? this.donation.payouts : []
			if (!payouts || payouts.length === 0) return false
			return payouts.every((p) => p.stripe_error)
		},
		pending() {
			const institutions = this.donation ? this.donation.institutions : []
			return (
				!institutions ||
				this.donation.payouts_created_count !== institutions.length
			)
		}
	},
	methods: {
		...mapActions(['setRoundupDetail', 'getDonations']),
		formatDate,
		currencyFormat,
		getSubtitle(institutions) {
			if (institutions.length == 1) {
				const name = institutions[0].name
				if (name.length > 30) {
					return name.substring(0, 30) + '...'
				}
				return name
			}
			return institutions.length + ' nonprofits'
		},
		isMatched(donation) {
			return (
				new Date(donation.created_at).getFullYear() === 2021 &&
				new Date(donation.created_at).getMonth() === 11
			)
		},
		navigateToDonation(donation) {
			if (this.pending) {
				this.showPendingModal = true
				this.getDonations()
			} else {
				this.setRoundupDetail(donation)
				this.navigate(
					{
						name: 'profile-donation-summary-details',
						params: { uuid: donation.id },
						props: { uuid: donation.id }
					},
					'forward'
				)
			}
		}
	},
	mounted() {
		if (this.pending) {
			setTimeout(() => {
				this.getDonations()
			}, 10000)
		}
	}
}
</script>

<style lang="scss" scoped>
.pending {
	opacity: 0.5 !important;
}
.money {
	font-weight: $font-medium !important;
}
.matched-banner {
	opacity: 1;
	position: absolute;
	z-index: 5;
	bottom: 8px;
	left: 20px;
	p {
		color: $roundup-navy;
		font-size: 12px !important;
		display: inline-block;
		margin-bottom: 0px !important;
	}
}
</style>
