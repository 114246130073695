<template>
	<nav-footer v-if="showPrivate" :tabs="filterTabs('private')" />
	<nav-footer v-else :tabs="filterTabs('public')" />
</template>

<script>
import navFooter from './NavFooter.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'nav-footer-controller',
	components: { navFooter },
	data() {
		return {
			tabs: [
				{
					name: 'give',
					icon: 'fas fa-hand-holding-usd',
					text: 'Give',
					domain: 'private'
				},
				{
					name: 'discover',
					icon: 'far fa-compass',
					text: 'Discover',
					domain: 'private'
				},

				{
					name: 'public-discover',
					icon: 'far fa-compass',
					text: 'Discover',
					domain: 'public'
				},
				{
					name: 'community',
					icon: 'fas fa-users',
					text: 'Community',
					domain: 'public'
				},
				{
					name: 'community',
					icon: 'fas fa-users',
					text: 'Community',
					domain: 'private'
				},
				{
					name: 'profile',
					icon: 'fas fa-user-circle',
					text: 'Profile',
					domain: 'private',
					badge: ['cardExpired', 'cardErrors']
				},
				{
					name: 'login',
					icon: 'fas fa-user-circle',
					text: 'Log In',
					domain: 'public'
				}
			]
		}
	},
	computed: {
		...mapGetters(['isLoggedIn']),
		showPrivate() {
			return this.isLoggedIn
		}
	},
	methods: {
		filterTabs(type = 'public') {
			return this.tabs.filter((el) =>
				type === 'public'
					? el.domain === 'public' || el.domain === 'mixed'
					: el.domain === 'private' || el.domain === 'mixed'
			)
		}
	}
}
</script>

<style lang="scss" scoped></style>
