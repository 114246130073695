export default {
	/* ****** PUBLIC PAGES ******* */

	login: () => import('@/pages/Public/Login/LoginView'),
	forgotPassword: () => import('@/pages/Public/Login/ForgotPassword'),
	resetPassword: () => import('@/pages/Public/Login/PublicResetPassword'),
	handleTokenAuth: () => import('@/pages/Public/Login/HandleTokenAuthView'),
	pageNotFound: () => import('@/pages/Public/404.vue'),
	community: () => import('@/pages/Mixed/Community/CommunityView'),
	communityEventsDetail: () =>
		import('@/pages/Mixed/Community/Events/EventsDetailView'),

	/* ****** PRIVATE PAGES ******* */
	// HOME
	give: () => import('@/pages/Private/Home/HomeView'),
	oneTimeGift: () => import('@/pages/Private/Home/OneTimeGiftView'),
	// thankyou: () => import('@/pages/Private/Home/ThankyouView'),

	// MY CAUSES
	myCauses: () => import('@/pages/Private/Causes/MyCausesView'),
	confirmDonation: () => import('@/pages/Private/Causes/ConfirmDonation'),

	//PROFILE
	profile: () => import('@/pages/Private/Profile/ProfileView'),
	profileEdit: () => import('@/pages/Private/Profile/Edit/ProfileEditView'),
	paymentOptions: () =>
		import(
			'@/pages/Private/Profile/PaymentOptions/PaymentOptionsController'
		),
	paymentOptionsEditCardView: () =>
		import(
			'@/pages/Private/Profile/PaymentOptions/PaymentOptionsEditCardView'
		),

	profileSettings: () =>
		import('@/pages/Private/Profile/Settings/SettingsView'),
	profileSettingsNotificationPreferences: () =>
		import(
			'@/pages/Private/Profile/Settings/Notifications/NotificationsView'
		),

	profileSettingsResetPassword: () =>
		import(
			'@/pages/Private/Profile/Settings/ResetPassword/ResetPasswordView'
		),
	profileSupport: () => import('@/pages/Private/Profile/Support/SupportView'),
	profileSupportContactUs: () =>
		import('@/pages/Private/Profile/Support/ContactUs/ContactUsView'),
	donationSettingsList: () =>
		import('@/pages/Private/Profile/Donations/DonationsSettingsView'),
	donationPreferences: () =>
		import('@/pages/Private/Profile/Donations/Preferences'),
	roundUpAccounts: () =>
		import(
			'@/pages/Private/Profile/LinkedAccounts/LinkedAccountsController'
		),
	donationSummary: () => import('@/pages/Private/Profile/Donations/Summary'),
	donationSummaryDetail: () =>
		import(
			'@/pages/Private/Profile/Donations/Summary/DonationSummaryDetailView'
		),
	donationFailedSummary: () =>
		import('@/pages/Private/Profile/Donations/Summary/FailedDonationsView'),
	transactionSummary: () => import('@/pages/Private/Profile/Transactions'),

	institutionDetails: () =>
		import(
			'@/pages/Private/InstitutionDetails/InstitutionDetailsController.vue'
		),
	/* ****** PUBLIC/PRIVATE PAGES ******* */

	discover: () => import('@/pages/Mixed/Discover/DiscoverView'),
	categories: () => import('@/pages/Mixed/Discover/DiscoverCategoriesView'),
	details: () => import('@/pages/Mixed/Discover/DiscoverDetailsView'),
	results: () => import('@/pages/Mixed/Discover/DiscoverResultsView'),
	thankYou: () => import('@/pages/Mixed/ThankYouView'),

	preloadLaunch() {
		this.login()
	},

	preloadHome() {
		this.home()
		this.discover()
		this.myCauses()
		this.profile()
	}
}
