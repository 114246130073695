export default {
	install: function (Vue) {
		Vue.prototype.setAlert = function (props) {
			for (var prop in props) {
				let value = props[prop]
				switch (prop) {
					case 'alertTimeout':
						this.$store.dispatch('setAlertTimeout', value)
						break
					case 'showAlert':
						this.$store.dispatch('setShowAlert', value)
						break
					case 'alertColor':
						this.$store.dispatch('setAlertColor', value)
						if (value === 'error') {
							this.$store.dispatch('setAlertTimeout', 6000)
						}
						break
					case 'alertPosition':
						this.$store.dispatch('setAlertPosition', value)
						break
					case 'alertText':
						this.$store.dispatch('setAlertText', value)
						break
					case 'alertCount':
						this.$store.dispatch('setAlertCount', value)
						break
				}
			}
			if (props.doNotReroute) {
				return
			}
			// default alertColor is 'success'
			if (!props.alertColor || props.alertColor === 'success') {
				if (props.nextRoute) {
					this.navigate({ name: props.nextRoute })
				} else {
					this.navigate({}, '', 'back')
				}
			}
		}
	}
}
