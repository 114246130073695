import axios from '../../network/axios'
import { analytics } from '@/utilities/tracker'

import {
	ONETIME_DONATION_ERROR,
	ONETIME_DONATION_SUCCESS,
	ONETIME_DONATION_REQUEST
} from '../types'

import _ from 'lodash'

const state = {
	item: {},
	isLoading: false,
	error: null
}

const actions = {
	guestDonateNow({ commit }, data) {
		commit(ONETIME_DONATION_REQUEST)
		const {
			institution_uuid,
			institution_name,
			amount,
			honoree_full_name
		} = data
		return new Promise((resolve, reject) => {
			axios
				.post('/api/guest/donations/instant', data)
				.then((resp) => {
					analytics.track('guest_one_time_donation', {
						institution_uuid,
						institution_name,
						amount,
						in_honor_of: !!honoree_full_name
					})
					commit(ONETIME_DONATION_SUCCESS, resp)
					resolve()
				})
				.catch(({ response }) => {
					const err = _.get(response, 'data.message', response)
					commit(ONETIME_DONATION_ERROR, err)
					reject(err)
				})
		})
	},
	guestEventDonateNow({ commit }, data) {
		commit(ONETIME_DONATION_REQUEST)
		const {
			institution_uuid,
			institution_name,
			amount,
			honoree_full_name
		} = data
		return new Promise((resolve, reject) => {
			axios
				.post('/api/guest/event/instant', data)
				.then((resp) => {
					analytics.track('event_one_time_donation', {
						institution_uuid,
						institution_name,
						amount,
						in_honor_of: !!honoree_full_name
					})
					commit(ONETIME_DONATION_SUCCESS, resp)
					resolve()
				})
				.catch(({ response }) => {
					const err = _.get(response, 'data.message', response)
					commit(ONETIME_DONATION_ERROR, err)
					reject(err)
				})
		})
	}
}

const mutations = {
	[ONETIME_DONATION_REQUEST](state) {
		state.isLoading = true
	},
	[ONETIME_DONATION_SUCCESS](state, donation) {
		state.isLoading = false
		state.item = donation
	},
	[ONETIME_DONATION_ERROR](state, error) {
		state.isLoading = false
		state.error = error
	}
}

export default {
	state,
	mutations,
	actions
}
