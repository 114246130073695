import axios from '../../network/axios'
import { GROUP_LIST, GROUP_LIST_REQUEST } from '../types'

const state = {
	isFetching: false,
	items: [],
	totalItems: null
}

const getters = {
	groups: (state) => state.items,
	groupListLoading: (state) => state.isFetching
}

const actions = {
	getGroups({ commit }, params) {
		return new Promise((resolve) => {
			commit(GROUP_LIST_REQUEST)
			axios.get('/institutions/groups/all' + query).then(({ data }) => {
				commit(GROUP_LIST, data)
				resolve()
			})
		})
	},
	searchGroups({ commit }, query) {
		return new Promise((resolve, reject) => {
			commit(GROUP_LIST_REQUEST)
			axios
				.get('/institutions/groups/search?query=' + query)
				.then(({ data }) => {
					window.analytics.track('search_groups')
					commit(GROUP_LIST, data)
					resolve()
				})
				.catch((err) => reject(err))
		})
	}
}

const mutations = {
	[GROUP_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[GROUP_LIST](state, resp) {
		state.isFetching = false
		state.items = resp.results
		state.totalItems = resp.total
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
