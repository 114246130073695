<template>
	<v-app-bar
		class="header-bar"
		style="overflow-x: hidden !important"
		flat
		prominent
	>
		<v-app-bar-nav-icon
			style="margin-top: 70px; color: white; margin-left: 16px"
			@click="$emit('backClick')"
		>
			<v-icon size="16">fas fas fa-arrow-left </v-icon>
		</v-app-bar-nav-icon>
		<v-app-bar-title style="max-width: 100%; padding-left: 0 !important">
			<div style="display: flex; flex-direction: row; margin-left: 0px">
				<v-img
					v-if="hasImg"
					:src="logo"
					contain
					max-width="40px"
					style="border-radius: 4px !important"
				></v-img>
				<v-icon
					v-else
					style="
						width: 48px;
						height: 48px;
						border: 1px solid #f6f7f9;
						border-radius: 5px;
						color: #9bb8ff;
					"
					>fas fa-hand-holding-heart</v-icon
				>
				<div style="margin-left: 15px; width: 250px">
					<p class="mb-0" style="color: white; font-size: 0.875rem">
						{{ cta }}
					</p>
					<h3
						style="color: white !important; font-size: 0.875rem"
						class="mb-0 ellipsis"
					>
						{{ causeName }}
					</h3>
				</div>
			</div>
		</v-app-bar-title>
	</v-app-bar>
</template>

<script>
export default {
	name: 'onboardingHeader',
	props: {
		institution: {
			default: null
		},
		event: {
			default: null
		},
		cta: {
			default: 'Start Supporting'
		}
	},
	computed: {
		hasImg() {
			return (
				this.institution &&
				(this.institution.logo_url || this.institution.brand.logo_url)
			)
		},
		logo() {
			return this.institution.logo_url || this.institution.brand.logo_url
		},
		causeName() {
			if (this.event) {
				return this.event.name
			} else {
				return this.institution && this.institution.name
			}
		}
	}
}
</script>

<style scoped lang="scss">
.header-bar::v-deep .v-toolbar__content {
	padding-left: 0px !important;
}
</style>
