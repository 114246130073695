import axios from '../../network/axios'
import { LINK_LIST, LINK_LIST_REQUEST } from '../types'

const state = {
	isFetching: false,
	items: []
}

const getters = {
	links: (state) => state.items
}

const actions = {
	getLinks({ commit }) {
		return new Promise((resolve) => {
			commit(LINK_LIST_REQUEST)
			axios.get('/me/links').then(({ data }) => {
				commit(LINK_LIST, data)
				resolve()
			})
		})
	},
	getPublicToken(_, link) {
		return new Promise((resolve, reject) => {
			axios
				.get('/links/' + link.id + '/token')
				.then(({ data }) => {
					resolve(data)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	deleteLink({ dispatch }, link) {
		return new Promise((resolve, reject) => {
			axios
				.delete('/links/' + link.id)
				.then(({ data }) => {
					window.analytics.track('delete_linked_account', { link })
					dispatch('getLinks')
					resolve(data)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	refreshLink({ dispatch }, link) {
		return new Promise((resolve, reject) => {
			axios
				.get('/links/refresh/' + link.id)
				.then(({ data }) => {
					window.analytics.track('refresh_linked_account', { link })
					dispatch('getLinks')
					resolve(data)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}
}

const mutations = {
	[LINK_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[LINK_LIST](state, links) {
		state.isFetching = false
		state.items = links
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
