import { get } from '../../utilities'
import {
	GET_TASK_LIST,
	GET_TASK_LIST_SUCCESS,
	GET_TASK_LIST_ERROR
} from '../types'

const tasks = [
	{
		name: 'Link RoundUp Accounts',
		property: ['hasAccounts'],
		description:
			'Add accounts to enable rounding up your transactions for donations',
		ctaLink: {
			name: 'profile-linked-accounts'
		},
		id: 1,
		cta: 'Get Started',
		icon: 'far fa-credit-card'
	},
	{
		name: 'Support More Causes',
		icon: 'fas fa-hand-holding-heart',
		property: ['hasMultipleCauses'],
		description: 'Discover and support all the causes you care about!',
		cta: 'Find Your Passion',
		ctaLink: {
			name: 'discover'
		},
		id: 2
	},
	{
		name: 'Add Personal Details',
		icon: 'far fa-user',
		property: ['firstName', 'lastName'],
		description: 'Give your organizations some details about yourself!',
		ctaLink: {
			name: 'profile-edit'
		},
		id: 3,
		cta: 'Update Profile'
	},
	{
		name: 'Add A Phone Number',
		icon: 'fas fa-phone',
		property: ['hasPhone'],
		description: 'This helps us keep you up to date with your account.',
		ctaLink: {
			name: 'profile-edit'
		},
		cta: 'Update Profile',
		id: 4
	}
]

const state = {
	items: [],
	loading: false
}

const getters = {
	taskList: (state) => state.items
}

const actions = {
	getTasks({ commit, rootGetters }) {
		commit(GET_TASK_LIST)
		let incompleteTasks = []

		let dismissed = localStorage.dismissedList || '[]'
		dismissed = JSON.parse(dismissed)

		tasks.forEach((task) => {
			let isComplete = task.property.every((prop) => rootGetters[prop])

			if (!isComplete && !dismissed.includes(task.id)) {
				incompleteTasks.push(task)
			}
		})

		commit(GET_TASK_LIST_SUCCESS, incompleteTasks)
	},
	resetTasks({}) {
		localStorage.dismissedList = '[]'
	}
}

const mutations = {
	[GET_TASK_LIST](state) {
		state.loading = true
	},
	[GET_TASK_LIST_SUCCESS](state, items) {
		state.items = items
		state.loading = false
	},
	[GET_TASK_LIST_ERROR](state, error) {
		state.loading = false
		state.error = error
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
