import axios from '../../network/axios'
import { ACCOUNT_LIST, ACCOUNT_LIST_REQUEST, ACCOUNT_TOGGLE } from '../types'

const state = {
	isFetching: false,
	items: []
}

const getters = {
	accounts: (state) => state.items
}

const actions = {
	getAccounts({ commit }) {
		return new Promise((resolve) => {
			commit(ACCOUNT_LIST_REQUEST)
			axios.get('/me/accounts').then(({ data }) => {
				commit(ACCOUNT_LIST, data)
				resolve()
			})
		})
	},
	toggleAccount({ commit }, account) {
		return new Promise((resolve) => {
			axios.put('/accounts/' + account.id + '/toggle').then(() => {
				window.analytics.track('toggle_account', { account })
				commit(ACCOUNT_TOGGLE, account)
				resolve()
			})
		})
	}
}

const mutations = {
	[ACCOUNT_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[ACCOUNT_LIST](state, accounts) {
		state.isFetching = false
		state.items = accounts
	},
	[ACCOUNT_TOGGLE](state, account) {
		account.deleted_at = account.deleted_at ? null : new Date()
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
