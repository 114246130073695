import axios from '../../network/axios'
import {
	ROUNDUP_DETAIL,
	ROUNDUP_DETAIL_REQUEST,
	ROUNDUP_DETAIL_ERROR
} from '../types'

const state = {
	isFetching: false,
	item: {},
	paid: true,
	error: null
}

const getters = {}

const actions = {
	setRoundupDetail({ commit }, roundup) {
		commit(ROUNDUP_DETAIL, roundup)
	},
	getRoundupDetail({ commit }, uuid) {
		return new Promise((resolve) => {
			commit(ROUNDUP_DETAIL_REQUEST)
			axios
				.get(`/roundups/${uuid}`)
				.then(({ data }) => {
					commit(ROUNDUP_DETAIL, data)
					resolve()
				})
				.catch((error) => commit(ROUNDUP_DETAIL_ERROR, error))
		})
	},

	retryRoundup({ dispatch, commit }, uuid) {
		return new Promise((resolve) => {
			commit(ROUNDUP_DETAIL_REQUEST)
			axios
				.put(`/roundups/retry/${uuid}`)
				.then(() => {
					dispatch('getRoundupDetail')
					resolve()
				})
				.catch((error) => commit(ROUNDUP_DETAIL_ERROR, error))
		})
	},
	emailRoundupReceipt({ commit }, uuid) {
		return new Promise((resolve, reject) => {})
	},
	downloadRoundupReceipt({ commit }, uuid) {
		return new Promise((resolve, reject) => {})
	}
}

const mutations = {
	[ROUNDUP_DETAIL_REQUEST](state) {
		state.error = null
		state.isFetching = true
	},
	[ROUNDUP_DETAIL_ERROR](state, error) {
		state.isFetching = false
		state.error = error
	},
	[ROUNDUP_DETAIL](state, data) {
		state.isFetching = false
		state.item = data
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
