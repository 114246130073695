/* eslint-disable no-console */
import axios from '../../network/axios'
import { get } from '../../utilities'
import moment from 'moment'
import { analytics } from '@/utilities/tracker'

import {
	LOGIN,
	LOGOUT,
	USER_SET_CURRENT,
	USER_REMOVE_CURRENT,
	USER_SUCCESS_STEP,
	USER_SET_TEMP,
	USER_REMOVE_TEMP,
	ACCOUNT_LIST,
	REGISTER_REQUEST_ERROR,
	REGISTER_REQUEST
} from '../types'

// initial state
const state = {
	current: null,
	token: localStorage.getItem('token'),
	isLoggedIn: !!localStorage.getItem('token'),
	tempPassword: '',
	isRegistering: false
}

const getters = {
	user: (state) => state.current,
	isRegistering: (state) => state.isRegistering,
	fullName: (state) => {
		return [
			get(state, 'current.first_name', ''),
			get(state, 'current.last_name', '')
		].join(' ')
	},
	firstName: (state) => {
		return get(state, 'current.first_name', '')
	},
	lastName: (state) => {
		return get(state, 'current.last_name')
	},
	hasPhone: (state) => state.current.phone,
	myInitials: (state) => {
		return (
			get(state, 'current.first_name', ' ').substring(0, 1) +
			get(state, 'current.last_name', ' ').substring(0, 1)
		)
	},
	organization: (state, getters) => {
		return getters.organizations.map((item) => item.name).join(', ')
	},
	organizations: (state) => {
		return [...get(state, 'current.institutions', [])].sort((a, b) =>
			a.name > b.name ? 1 : -1
		)
	},
	group: (state) => get(state, 'current.group', null),
	cause: (state) => {
		var group = get(state, 'current.group', null)
		var org = get(state, 'current.institutions', [])
		if (group) {
			return {
				name: group.name,
				icon: group.icon,
				category: group.category,
				fact: group.fact,
				type: 'group',
				uuid: group.uuid
			}
		}
		if (org && org.length > 0) {
			org = org[0]
			return {
				type: 'org',
				uuid: org.uuid,
				username: org.username,
				name: org.name,
				icon: org.logo_url,
				category: org.category,
				fact: `Your change allows us to ${org.full_impact_statement}!`
			}
		}
		return null
	},
	isLoggedIn: (state) => state.isLoggedIn,
	hasUser: (state) => !!state.current,
	isDonating: (state) => state.current.is_donating,
	maxDonation: (state) => get(state, 'current.maximum_donation', null),
	minDonation: (state) => get(state, 'current.minimum_donation'),
	nextSignupStep: (state) => {
		if (!get(state, 'current.has_institution', false)) {
			return 'institutions'
		} else if (!get(state, 'current.minimum_donation', false)) {
			return 'mindonation'
		} else if (!get(state, 'current.has_valid_card', false)) {
			return 'card'
		} else if (!get(state, 'current.has_accounts', false)) {
			return 'pre-plaid'
		} else {
			return 'home'
		}
	},
	hasAccounts: (state) => get(state, 'current.has_accounts', false),
	hasCompletedSignup: (state) => {
		return get(state, 'current.has_completed_signup', false)
	},
	hasValidCard: (state) => get(state, 'current.has_valid_card', false),
	activeCard: (state) => {
		return {
			last4: get(state, 'current.card_last_four', null),
			brand: get(state, 'current.card_brand', null)
		}
	},
	hasTOS: () => true,
	wholeDollar: () => get(state, 'current.whole_transaction_amount', 0),
	missingProfileInfo: (state) => {
		const user = state.current
		return !(user.first_name && user.last_name && user.phone && user.email)
	},
	cardErrors: (state) => {
		return !get(state, 'current.has_valid_card', false)
	},
	cardExpired: (state) => {
		if (state.current && state.current.card_exp)
			return new Date(moment.utc(state.current.card_exp)) < new Date()
		return false
	}
}

const actions = {
	login({ commit, dispatch }, creds) {
		analytics.track('login-attempted', {
			email: creds.email,
			method: 'email-password'
		})
		return new Promise((resolve, reject) => {
			axios
				.post('/login', creds)
				.then(({ data }) => {
					analytics.track(
						'login-success',
						{
							email: creds.email
						},
						true
					)
					try {
						window.analytics.identify(data.id, {
							email: data.email
						})
					} catch {
						null
					}
					commit(USER_SET_CURRENT, data)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					resolve()
				})
				.catch(({ response }) => {
					if (response.data) {
						reject(response.data)
					} else {
						reject(
							new Error(
								'Incorrect email or password combination. Please try again.'
							)
						)
					}
				})
		})
	},

	loginWithToken({ commit, dispatch }, token) {
		analytics.track('login-attempted', {
			method: 'token'
		})
		return new Promise((resolve, reject) => {
			axios
				.post('/loginWithToken', { token })
				.then(({ data }) => {
					window.analytics.track('login_with_token', {
						email: data.email
					})
					try {
						window.analytics.identify(data.id, {
							email: data.email
						})
					} catch {
						null
					}
					commit(USER_SET_CURRENT, data)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					resolve()
				})
				.catch(({ response }) => {
					if (response.data) {
						reject(response.data)
					} else {
						reject(
							new Error(
								'Incorrect email or password combination. Please try again.'
							)
						)
					}
				})
		})
	},
	loginWithGoogle({ commit, dispatch }, access_token) {
		analytics.track('login-attempted', {
			method: 'google-sso'
		})
		return new Promise((resolve, reject) => {
			axios
				.post('/social/google', { access_token })
				.then(({ data }) => {
					analytics.track(
						'login_with_google',
						{
							email: data.email
						},
						true
					)
					try {
						window.analytics.identify(data.id, {
							email: data.email
						})
					} catch {
						null
					}
					commit(USER_SET_CURRENT, data)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((response) => {
					if (response.data) {
						reject(response.data)
					} else {
						reject(
							new Error(
								'Could not login with google. Please try again.'
							)
						)
					}
				})
		})
	},

	loginWithApple({ commit, dispatch }, access_token) {
		analytics.track('login-attempted', {
			method: 'apple-sso'
		})
		return new Promise((resolve, reject) => {
			let social = '/social/apple'
			if (process.env.VUE_APP_TYPE !== 'ios') {
				social = '/social/apple/web'
			}
			axios
				.post(social, { access_token })
				.then(({ data }) => {
					analytics.track('login_with_apple', {
						email: data.email
					})
					try {
						window.analytics.identify(data.id, {
							email: data.email
						})
					} catch {
						null
					}
					commit(USER_SET_CURRENT, data)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((response) => {
					console.log(response)
					if (response.data) {
						reject(response.data)
					} else {
						reject(
							new Error(
								'Could not login with apple. Please try again.'
							)
						)
					}
				})
		})
	},

	logout({ commit }) {
		analytics.track('logout')
		return new Promise((resolve) => {
			commit(LOGOUT)
			resolve()
		})
	},

	getCurrentUser({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get('/me')
				.then(({ data }) => {
					commit(USER_SET_CURRENT, data)
					resolve()
				})
				.catch(({ response }) => {
					if (get(response, 'status', 200) === 401) {
						commit('LOGOUT')
					}
					reject(response)
				})
		})
	},

	updateUser({ commit }, user) {
		return new Promise((resolve, reject) => {
			axios
				.put('/me', user)
				.then(({ data }) => {
					analytics.track('update_user_info')
					commit(USER_SET_CURRENT, data)
					resolve()
				})
				.catch(({ response }) => {
					if (get(response, 'status', 200) === 401) {
						commit('LOGOUT')
					}
					reject(response.data)
				})
		})
	},

	uploadProfilePhoto({ dispatch }, file) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/profilePhoto', file)
				.then(() => {
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((err) => reject(err))
		})
	},

	profileImageDelete({ dispatch }) {
		return new Promise((resolve, reject) => {
			axios
				.delete('/me/profilePhoto')
				.then(() => {
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((err) => reject(err))
		})
	},
	changePassword(_, passwords) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/changePassword', passwords)
				.then(({ data }) => {
					analytics.track('user_change_password')
					resolve(data)
				})
				.catch(({ response }) => {
					reject(response.data)
				})
		})
	},

	checkUserLoad({ state, dispatch }) {
		if (state.current) {
			return new Promise((resolve) => {
				resolve()
			})
		} else {
			return dispatch('getCurrentUser')
		}
	},

	register({ commit }, user) {
		commit(REGISTER_REQUEST)
		const headers = {}
		if (!user.first_name && !user.last_name && !user.phone) {
			headers['register-by'] = 'email_only'
		}

		const utmData = {
			referral_code: user.referral_code,
			utm_source: user.utm_source,
			utm_medium: user.utm_medium,
			utm_campaign: user.utm_campaign
		}

		analytics.track('user_sign_up_attempted', utmData)

		return new Promise((resolve, reject) => {
			axios
				.post('/register', user, { headers })
				.then(({ data }) => {
					analytics.track('user_sign_up', utmData, true)
					commit(USER_SET_CURRENT, data)
					analytics.identify()
					commit(LOGIN, { token: data.token })
					resolve(data)
				})
				.catch(({ response }) => {
					analytics.track('user_sign_up_error', utmData)
					commit(REGISTER_REQUEST_ERROR)
					reject(response.data)
				})
		})
	},

	forgotPassword(context, email) {
		return new Promise((resolve, reject) => {
			axios
				.post('/password/forgot', { email: email })
				.then(({ data }) => {
					analytics.track('user_forgot_password')
					resolve(data)
				})
				.catch(({ response }) => {
					reject(response.data)
				})
		})
	},

	resetPassword({ commit }, creds) {
		return new Promise((resolve, reject) => {
			axios
				.post('/password/reset', creds)
				.then(({ data }) => {
					analytics.track('user_set_password')
					commit(USER_SET_CURRENT, data)
					resolve(data)
				})
				.catch(({ response }) => {
					reject(response.data)
				})
		})
	},

	closeAccount({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.delete('/me')
				.then(() => {
					analytics.track('user_close_account')
					commit(LOGOUT)
					resolve()
				})
				.catch(({ response }) => {
					reject(response.data)
				})
		})
	},

	fetchLinkToken({}, path) {
		const body = {}
		if (process.env.VUE_APP_TYPE !== 'web') {
			body.redirectUri = 'https://roundup.app/RedirectToApp'
			if (path) {
				body.redirectUri += `/${path}`
			}
		}

		return new Promise((resolve, reject) => {
			axios
				.post('/me/plaidToken', body)
				.then(({ data }) => resolve(data))
				.catch((err) => reject(err))
		})
	},
	attachLink({ commit, dispatch }, params) {
		return new Promise((resolve, reject) => {
			axios.post('/me/bank', params).then(
				({ data }) => {
					analytics.track('user_link_bank')
					dispatch('getLinks')
					commit(ACCOUNT_LIST, data)
					commit(USER_SUCCESS_STEP, 'has_accounts')
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	attachCard({ dispatch, commit }, { token, amount }) {
		return new Promise((resolve, reject) => {
			axios.post('/me/card', { public_token: token, amount }).then(
				({ data }) => {
					analytics.track('user_link_funding_source')
					commit(USER_SUCCESS_STEP, 'has_valid_card')
					dispatch('getCurrentUser')
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	updateCard({ dispatch, commit }, params) {
		return new Promise((resolve, reject) => {
			axios.put('/me/card', params).then(
				({ data }) => {
					analytics.track('user_update_funding_source')
					dispatch('getCurrentUser')
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	removeCard({ dispatch, commit }) {
		return new Promise((resolve, reject) => {
			axios.put('/me/card/remove').then(
				({ data }) => {
					analytics.track('user_remove_funding_source')
					dispatch('getCurrentUser')
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	attachInstitution({ commit, dispatch }, institution) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/institution', { institution_id: institution.id })
				.then(
					({ data }) => {
						analytics.track('user_select_cause', {
							institution_id: institution.id
						})
						commit(USER_SUCCESS_STEP, 'has_institution')
						dispatch('getCurrentUser')
						resolve(data)
					},
					({ response }) => {
						reject(response.data)
					}
				)
		})
	},

	finishSignup({ commit }) {
		return new Promise((resolve, reject) => {
			axios.post('/me/finishSignup').then(
				({ data }) => {
					analytics.track('user_complete_sign_up')
					commit(USER_SUCCESS_STEP, 'has_completed_signup')
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	setMultiplier({ commit }, multiplier) {
		return new Promise((resolve, reject) => {
			axios.post('/me/multiplier', { multiplier }).then(
				({ data }) => {
					analytics.track('user_set_multiplier', {
						multiplier
					})
					commit(USER_SET_CURRENT, data)
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	acceptTOS({ commit }) {
		return new Promise((resolve, reject) => {
			axios.put('/me/acceptTOS').then(
				(data) => {
					analytics.track('user_accepted_TOS')
					commit(USER_SET_CURRENT, data)
					resolve()
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},
	updateMaximum({ dispatch }, maximumDonation) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/maximumDonation', {
					maximum_donation: maximumDonation
				})
				.then(() => {
					analytics.track('preferences_updated', {
						preference_type: 'maximum_donation',
						preference_value: maximumDonation
					})
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((err) => {
					dispatch('getCurrentUser')
					reject(err)
				})
		})
	},
	updateWholeDollar({ dispatch }, wholeDollar) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/wholeDollar', {
					whole_transaction_amount: wholeDollar
				})
				.then(() => {
					analytics.track('preferences_updated', {
						preference_type: 'whole_dollar_roundups',
						preference_value: wholeDollar
					})
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((err) => {
					dispatch('getCurrentUser')
					reject(err)
				})
		})
	},
	updateCoverFees({ dispatch }, enabled) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/coverStripeFees', {
					cover_stripe_fees: enabled
				})
				.then(() => {
					analytics.track('preferences_updated', {
						preference_type: 'cover_stripe_fees',
						preference_value: enabled
					})
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((err) => {
					dispatch('getCurrentUser')
					reject(err)
				})
		})
	},
	registerDevice({ dispatch }, token) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/device', { token })
				.then(() => {
					dispatch('getCurrentUser')
					resolve()
				})
				.catch((err) => {
					dispatch('getCurrentUser')
					reject(err)
				})
		})
	}
}

// mutations
const mutations = {
	[LOGIN](state, tokens) {
		state.isLoggedIn = true
		state.token = tokens.token

		// Store token as well as set it for axios use
		localStorage.setItem('token', tokens.token)
	},

	[LOGOUT](state) {
		state.isLoggedIn = false
		state.token = null
		state.current = null

		// Remove local store
		localStorage.removeItem('token')
	},

	[REGISTER_REQUEST](state) {
		state.isRegistering = true
	},
	[REGISTER_REQUEST_ERROR](state) {
		state.isRegistering = false
	},
	[USER_SET_CURRENT](state, user) {
		state.isLoggedIn = true
		state.current = user
		state.isRegistering = false
	},
	[USER_REMOVE_CURRENT](state) {
		state.current = null
	},
	[USER_SUCCESS_STEP](state, step) {
		state.current[step] = true
	},
	[USER_SET_TEMP](state, temp) {
		state.tempPassword = temp
	},
	[USER_REMOVE_TEMP](state) {
		state.tempPassword = ''
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}
