import axios from 'axios'

import { SET_ONBOARDING_STEP } from '../types'

const state = {
	currentStep: 1
}

const getters = {}

const actions = {
	setOnboardingStep({ commit }, step) {
		commit(SET_ONBOARDING_STEP, step)
	},
	getNextOnboardingStep({ state }) {
		return state.currentStep + 1
	}
}

const mutations = {
	[SET_ONBOARDING_STEP](state, step) {
		state.currentStep = step
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
