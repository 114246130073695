<template>
	<div class="progress-bar" :class="[empty && 'empty']" id="progress">
		<div
			class="shell"
			:style="{
				'z-index': parseInt(current) < minLineValue ? '1' : '3'
			}"
		>
			<div
				class="bar"
				:style="{
					width: currentWidth
				}"
			>
				<span v-if="!empty && current > 0"
					>${{
						current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
					}}</span
				>
			</div>
		</div>
		<span
			class="min-line-value"
			:style="{ left: currentLeft }"
			v-show="showMinLine"
			>{{ formattedMinLineValue }} min
		</span>

		<v-divider
			:style="{ left: currentLeft }"
			style="
				height: 56px !important;
				max-height: 56px !important;
				min-height: 56px !important;
				border-style: dashed !important;
				z-index: 2;
			"
			class="min-line-divider"
			vertical
		></v-divider>
		<div
			v-if="!empty"
			style="
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
			"
		>
			<p style="flex: 1; font-size: 14px; color: #747a8d">
				${{
					min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ||
					current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				}}
			</p>
			<p
				style="
					flex: 1;
					text-align: right;
					font-size: 14px;
					color: #747a8d;
				"
			>
				${{ max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
			</p>
		</div>
	</div>
</template>

<script>
import { currencyFormat } from '../utilities'

export default {
	name: 'progress-bar',
	props: {
		current: { type: String },
		max: { type: Number },
		min: { type: Number },
		empty: { type: Boolean },
		showMinLine: { type: Boolean },
		minLineValue: { type: Number }
	},
	computed: {
		currentWidth() {
			if (!this.current || this.current === 0) {
				return '0%'
			}
			if (this.current > 0) {
				return (
					Math.max(Math.round((this.current / this.max) * 100), 12) +
					'%'
				)
			}
		},
		currentLeft() {
			if (!this.minLineValue || this.minLineValue === 0) {
				return '0%'
			}
			if (this.current > 0) {
				return (
					Math.max(
						Math.round((this.minLineValue / this.max) * 100),
						12
					) + '%'
				)
			}
		},

		formattedMinLineValue() {
			if (this.minLineValue) {
				return '$' + this.minLineValue
			}
			return '$0'
		}
	},
	method: {}
}
</script>

<style lang="scss">
.progress-bar {
	border-radius: 5px;
	width: 100%;
	padding: 5px;
	position: relative;
	h4 {
		color: $roundup-navy;
		margin: 0px;
		margin-bottom: 5px;
	}
	.shell {
		position: relative;

		height: 40px;
		width: 100%;
		border: 1px solid #1cd4e3;
		border-radius: 20px;
		background: $roundup-bone;
	}
	.min-line-divider {
		position: absolute;
		left: 50%;
		top: -5%;
		transform: translateX(-50%);
		border-color: #2553cd !important;
	}
	.min-line-value {
		position: absolute;
		left: 50%;
		top: -25%;
		transform: translateX(-50%);
		color: #2553cd;
		font-size: 12px;
	}
	&.empty .bar {
		background: $roundup-light-grey;
	}
	.bar {
		background: #c6f4f8;
		border-right: 1px solid #1cd4e3;

		height: 38px;
		width: 15px;
		border-radius: 20px;
		span {
			float: right;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 4px 5px;
			color: $roundup-charcoal;
			font-size: 14px;
		}
	}
}
</style>
