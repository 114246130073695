<template>
	<div
		class="nav-template px-0 ma-0"
		style="display: flex; flex-direction: column; align-items: center"
	>
		<nav-header-controller
			class="nav-template-header"
			ref="navTemplateHeader"
			@navButtonClick="handleNavButtonClick"
		/>

		<slot class="nav-template-header" name="header"> </slot>

		<slot name="body"> </slot>

		<slot class="nav-template-footer" name="footer"> </slot>

		<RoundUpAlert
			class="nav-template-alert"
			:alertTimeout="alertTimeout"
			:showAlert="showAlert"
			:alertColor="alertColor"
			:alertText="alertText"
		/>
		<nav-footer-controller
			class="nav-template-nav-footer"
			ref="navTemplateFooter"
		/>
	</div>
</template>

<script>
import NavFooterController from '../components/Nav/navFooterController.vue'
import NavHeaderController from '../components/Nav/NavHeaderController.vue'
import { mapState, mapGetters } from 'vuex'
import { RoundUpAlert } from '@/ui'

import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'

import { Capacitor } from '@capacitor/core'

export default {
	name: 'nav-template',
	components: { NavFooterController, NavHeaderController, RoundUpAlert },
	computed: {
		...mapState({
			nav: ({ nav }) => nav
		}),
		...mapGetters([
			'alertTimeout',
			'showAlert',
			'alertColor',
			'alertPosition',
			'alertText'
		])
	},
	data: () => {
		return {}
	},
	methods: {
		handleNavButtonClick() {
			this.$root.$emit('navClick', this.$router.history.current.name)
		},
		async awaitATT() {
			try {
				if (Capacitor.getPlatform() === 'ios') {
					try {
						const { status } =
							await AppTrackingTransparency.getStatus()
						if (status === 'notDetermined' || status === 'null') {
							await AppTrackingTransparency.requestPermission()
						}
					} catch (e) {
						console.warn('IOS Error', e)
					}
				}
			} catch (e) {
				console.warn('Authorization failed', e)
			}
		}
	},
	async mounted() {
		await this.awaitATT()
	}
}
</script>

<style lang="scss" scoped>
.nav-template {
	flex: 1;
	overflow-y: auto !important;
	position: relative;
	&-header {
		position: fixed;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
	}

	&-footer {
		position: fixed;
		left: 50%;
		bottom: 0%;
		transform: translateX(-50%);
	}
	&-alert {
		position: fixed;
		left: 50%;
		bottom: 80px;
		transform: translateX(-50%);
	}
}

.nav-template::v-deep .nav-template-nav-footer {
	position: fixed;
	left: 50%;
	bottom: 0%;
	transform: translateX(-50%);
}
.nav-template::v-deep .nav-template-nav-header {
	position: fixed;
	left: 50%;
	top: 0%;
	transform: translateX(-50%);
}
</style>
