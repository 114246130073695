import { reject } from 'lodash'
import axios from '../../network/axios'
import { get } from '../../utilities'
import {
	SET_COMMUNITY_EVENTS_LIST,
	GET_COMMUNITY_EVENTS_LIST,
	SET_COMMUNITY_EVENTS_LIST_ERROR
} from '../types'

const state = {
	events: [],
	isFetching: false,
	error: null
}

const getters = {}

const actions = {
	getCommunityEvents({ commit, state }) {
		return new Promise((resolve, reject) => {
			commit(GET_COMMUNITY_EVENTS_LIST)
			axios
				.get('/community/events')
				.then(({ data }) => {
					data = data.sort((a, b) =>
						a.created_at > b.created_at ? -1 : 1
					)
					commit(SET_COMMUNITY_EVENTS_LIST, data)
					resolve()
				})
				.catch((err) => {
					reject(err)
				})
		})
	}
}

const mutations = {
	[SET_COMMUNITY_EVENTS_LIST](state, events) {
		state.isFetching = false
		state.error = null
		state.events = events
	},
	[GET_COMMUNITY_EVENTS_LIST](state) {
		state.isFetching = true
		state.error = null
	},
	[SET_COMMUNITY_EVENTS_LIST_ERROR](state, error) {
		state.isFetching = false
		state.error = error
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
