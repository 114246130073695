<template>
	<v-card
		v-if="compact"
		v-bind="$attrs"
		class="font-regular detail-card-view roundup-compact"
		:style="{
			width: '100%',
			borderRadius: '8px'
		}"
		@click="$emit('detail', event)"
	>
		<v-img
			class="align-end no-bottom-border"
			:src="event.background_image_url"
			position="bottom"
		/>
		<v-card-title class="px-5 detail-card-view-title">
			{{ event.name }}
		</v-card-title>

		<v-divider class="mx-4" style="width: 12% !important" />
		<v-card-text class="px-5 detail-card-view-text">
			{{ truncate(event.summary) }}</v-card-text
		>
	</v-card>
	<v-card
		v-else
		v-bind="$attrs"
		class="font-regular detail-card-view"
		:style="{
			width: '100%',
			borderRadius: '20px'
		}"
	>
		<div :style="{ height: '10px' }" class="detail-card-view-stripe" />
		<v-card-title
			class="px-5 detail-card-view-title"
			style="font-size: 30px !important"
		>
			{{ event.name }}
		</v-card-title>
		<v-card-text
			class="px-5 detail-card-view-text"
			style="line-height: 1.625"
			v-html="event.description"
		/>
		<p
			class="px-5 mt-1 my-0 mb-5 detail-card-view-title"
			style="font-size: 24px !important"
			v-show="!compact"
		>
			Included Organizations
		</p>

		<span
			v-show="!compact"
			class="px-5"
			style="
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: flex-start;
				width: 100%;
				flex-wrap: wrap;
			"
		>
			<div
				v-for="(inst, i) in event.institutions"
				:key="i"
				class="mb-5"
				style="width: 40%"
			>
				<v-img
					@click="openOrg(inst.website_url)"
					:src="inst.logo_url"
					max-width="100px"
				/>
			</div>
		</span>
	</v-card>
</template>

<script>
export default {
	name: 'detail-card-view',
	props: {
		event: {
			type: Object,
			default: null
		},
		breakpoint: {
			type: String,
			default: null
		},
		compact: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({}),
	computed: {},
	methods: {
		openOrg(url) {
			window.open(url, '_blank')
		},
		truncate(str) {
			if (str) {
				let max

				switch (this.breakpoint || this.$vuetify.breakpoint.name) {
					case 'xs':
						max = 100
						break
					default:
						max = 110
				}

				return str.length > max ? str.slice(0, max - 25) + '...' : str
			}
		}
	},
	async mounted() {}
}
</script>

<style lang="scss" scoped>
.detail-card-view {
	box-shadow: 0px 2px 10px 0px rgba(72, 74, 80, 0.5) !important;

	&-title {
		font-size: 18px !important;
		color: $roundup-charcoal !important;
		font-weight: 600 !important;
	}

	&-text {
		font-size: 16px !important;
		color: $roundup-charcoal !important;
	}
	&-stripe {
		background-color: $roundup-navy !important;
	}
	.no-bottom-border {
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		border-bottom: 1px solid #dcdee3;
	}

	.header-background {
		background-color: $roundup-light-grey;
	}
}

.detail-card-view:hover.roundup-compact {
	cursor: pointer;
	box-shadow: 0px 0px 1px 1px $white, 0px 0px 6px 1px $roundup-bluegrad2 !important;
}
</style>
