<template>
	<div class="roundup-alert" :style="{ 'z-index': 205 }">
		<v-snackbar
			ref="snackbar"
			:timeout="1000000"
			:value="showAlert"
			:color="alertColor"
		>
			<v-icon size="20" v-if="icon"> {{ icon }} </v-icon>
			<span
				:style="{
					'margin-left': icon ? '5px' : ''
				}"
				id="text"
			>
				{{ alertText }}
			</span>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	name: 'roundup-alert',
	data() {
		return {
			icon: null
		}
	},
	props: [
		'alertTimeout',
		'showAlert',
		'alertColor',
		'alertPosition',
		'alertText',
		'alertCount'
	],
	methods: {},
	watch: {
		showAlert(v) {
			if (v) {
				let el = this.$refs.snackbar.$el.children[0]
				switch (this.alertColor) {
					case 'success':
						el.classList.add('success')
						this.icon = 'fa-check-circle'
						break
					case 'error':
						el.classList.add('error')
						this.icon = 'fas fa-exclamation-triangle'
						break
					default:
						return this.alertColor
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.roundup-alert::v-deep .v-snack__wrapper {
	width: 90% !important;
	min-width: 350px !important;
	max-width: 425px !important;
}
.roundup-alert::v-deep .v-snack__wrapper.success {
	background-color: #d9fdf5 !important;
	i {
		color: #07745c !important;
	}
	#text {
		color: #07745c !important;
		font-size: 0.875rem !important;
	}
}

.roundup-alert::v-deep .v-snack__wrapper.error {
	background-color: #ffe7ea !important;
	max-width: 90% !important;
	i {
		color: #db2137 !important;
	}
	#text {
		color: #db2137 !important;
		font-size: 0.875rem !important;
	}
}
</style>
