import axios from '../../network/axios'
import {
	UPDATE_SEARCH_QUERY,
	UPDATE_SEARCH_FILTERS,
	SEARCH_LIST_FETCHING,
	SEARCH_LIST_SUCCESS,
	SEARCH_LIST_FAIL,
	UPDATE_LAT_LONG
} from '../types.js'

const state = {
	items: [],
	filters: {
		query: null,
		category: null,
		subcategory: null,
		location: {
			city: null,
			state: null
		},
		zip: null,
		partner: null
	},
	total: 0,
	isFetching: false,
	aroundLatLng: null
}

const getters = {
	searchListResults: (state) => state.items,
	searchListQuery: (state) => state.filters.query,
	searchListFilters: (state) => state.filters,
	searchListFetching: (state) => state.isFetching,
	searchListAroundLatLong: (state) => state.aroundLatLng
}

// actions
const actions = {
	clearSearch({ commit }) {
		commit(UPDATE_SEARCH_QUERY, '')
	},
	updateQuery({ commit }, query) {
		commit(UPDATE_SEARCH_QUERY, query)
	},
	updateFilters({ commit }, filters) {
		commit(UPDATE_SEARCH_FILTERS, filters)
	},
	setSearchLatLong({ commit }, latLong) {
		commit(UPDATE_LAT_LONG, latLong)
	},
	searchLocally(
		{ commit },
		{ lat, long, radius, byIP = false, hitsPerPage = 10 }
	) {
		commit(SEARCH_LIST_FETCHING)

		const searchParams = {}
		searchParams.aroundRadius = radius
		if (byIP) {
			searchParams.aroundLatLngViaIP = true
		} else {
			searchParams.aroundLatLng = `${lat},${long}`
		}

		return new Promise((resolve, reject) => {
			axios
				.get('/api/search/institutions', {
					params: {
						params: { ...searchParams, hitsPerPage }
					}
				})
				.then(({ data }) => {
					commit(SEARCH_LIST_SUCCESS, data)
					resolve(data)
				})
				.catch((err) => {
					commit(SEARCH_LIST_FAIL, err)
					console.log(err)
					reject(err)
				})
		})
	},
	zipToLatLong(_, zip) {
		return new Promise((resolve, reject) => {
			axios
				.get(
					`https://thezipcodes.com/api/v1/search?zipCode=${zip}&countryCode=US&apiKey=77adc9c5e3bfae87f54f6c7325d813ea`
				)
				.then(({ data }) => {
					if (data.success) {
						resolve(data.location[0])
					} else {
						reject('Not Found')
					}
				})
				.catch((err) => reject(err))
		})
	}
}

const mutations = {
	[UPDATE_SEARCH_QUERY](state, response) {
		state.filters.query = response
	},
	[UPDATE_SEARCH_FILTERS](state, response) {
		state.filters = { ...state.filters, ...response }
	},
	[SEARCH_LIST_FETCHING](state) {
		state.isFetching = true
	},
	[UPDATE_LAT_LONG](state, latLong) {
		state.aroundLatLng = latLong
	},
	[SEARCH_LIST_SUCCESS](state, data) {
		state.items = data.hits
		state.total = data.nbHits
		state.aroundLatLng = data.aroundLatLng
		state.isFetching = false
	},
	[SEARCH_LIST_FAIL](state) {
		state.isFetching = false
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
