<template>
	<v-card class="confirm-card">
		<v-row style="padding-top: 16px !important">
			<v-spacer></v-spacer>
			<h3 v-if="title">{{ title }}</h3>
			<v-spacer></v-spacer>
		</v-row>
		<v-row>
			<v-spacer></v-spacer>
			<h1 style="margin-top: 20px">
				{{ formattedAmount }}
				<span style="color: white" v-if="donationType === 'Roundup'">
					min</span
				>
			</h1>
			<v-spacer></v-spacer>
		</v-row>
		<v-row class="vcenter">
			<v-spacer></v-spacer>
			<div class="details">
				<v-row class="row">
					<v-icon class="confirm-icon"
						>fas fa-hand-holding-heart</v-icon
					>
					<p style="max-width: 250px !important">
						{{ causeName }}
					</p>
					<v-spacer></v-spacer>
				</v-row>
				<v-row class="">
					<v-icon class="confirm-icon">fas fa-gift</v-icon>
					<p>{{ donationType }}</p>
					<v-spacer></v-spacer>
				</v-row>
				<v-row class="" v-if="startDate">
					<v-icon class="confirm-icon">far fa-calendar-alt</v-icon>
					<p>
						{{ donationType !== 'One-Time' ? 'Starting on' : '' }}
						{{
							donationType !== 'One-Time'
								? startDate
								: formattedCurrentDate
						}}
					</p>
					<v-spacer></v-spacer>
				</v-row>
				<v-row v-if="cardLastFour">
					<v-icon class="confirm-icon">far fa-credit-card</v-icon>
					<p>{{ cardBrand }} ***{{ cardLastFour }}</p>
					<v-spacer></v-spacer>
				</v-row>
			</div>
			<v-spacer></v-spacer>
		</v-row>
		<v-row
			style="
				margin-top: 50px;
				margin-left: 10px !important;
				margin-right: 10px !important;
			"
		>
			<Roundup-Button
				color="white"
				@click="$emit('submit')"
				block
				:loading="isLoading"
				:disabled="amount < 5 || preventSubmit || isLoading"
				>{{ cta }}</Roundup-Button
			>
		</v-row>

		<v-row v-if="this.remove" align="center" justify="center" class="mt-4">
			<v-spacer></v-spacer>
			<Roundup-Button
				color="clear"
				class="remove-org-btn"
				@click="() => $emit('removeNonprofit')"
				:loading="isLoading"
			>
				Cancel Donation
			</Roundup-Button>
			<v-spacer></v-spacer>
		</v-row>
	</v-card>
</template>

<script>
import moment from 'moment'
export default {
	name: 'confirm-donation-card',
	props: [
		'amount',
		'institution',
		'donationType',
		'cardLastFour',
		'cta',
		'cardBrand',
		'startDate',
		'isLoading',
		'event',
		'preventSubmit',
		'title',
		'remove',
		'feesAmount'
	],
	computed: {
		formattedAmount() {
			let amount = this.amount * 100
			let fees = this.feesAmount || 0
			if (fees < 99) {
				fees = fees * 100
			}
			let combine = amount + fees
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD'
			}).format(combine / 100)
		},
		formattedCurrentDate() {
			const date = new Date()
			return moment(date.toLocaleDateString()).format('MM/DD/YYYY')
		},
		causeName() {
			if (this.event) {
				return this.event.name
			}
			if (this.institution) {
				const inst = this.institution
				return inst.name
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.confirm-card {
	background: $roundup-purple !important;
	color: white !important;
	padding-bottom: 30px !important;
	h1,
	h2,
	h3,
	h4,
	p {
		color: white !important;
		margin-bottom: 0px !important;
	}
	.confirm-icon {
		color: white !important;
		margin-right: 10px !important;
	}
	.details {
		max-width: 280px !important;
		.row {
			margin-top: 25px !important;
		}
	}
	.remove-org-btn {
		color: white !important;
		margin-top: 16px !important;
	}
}
</style>
