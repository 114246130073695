export default {
	install: function (Vue) {
		Vue.prototype.navigate = function (
			route,
			direction = 'forward',
			presets = null
		) {
			if (presets) {
				switch (presets) {
					case 'back':
						return this.$router.back()
				}
			}

			if (!route.params) route.params = {}
			route.params.direction = direction

			this.$router.push(route)
		}

		Vue.prototype.setNav = function (props) {
			this.$store.dispatch('resetNav')

			for (var prop in props) {
				const value = props[prop]
				switch (prop) {
					case 'title':
						this.$store.dispatch('setNavTitle', value)
						break
					case 'showNavFooter':
						this.$store.dispatch('setShowNavFooter', value)
						break
					case 'showNavHeader':
						this.$store.dispatch('setShowNavHeader', value)
						break
					case 'showBack':
						this.$store.dispatch('setShowBack', value)
						break
					case 'showNavHeaderRightButton':
						this.$store.dispatch(
							'setShowNavHeaderRightButton',
							value
						)
						break
					case 'isOnboarding':
						this.$store.dispatch('setNavOnboarding', value)
						break
					case 'institution':
						this.$store.dispatch('setNavInstitution', value)
						break
					case 'event':
						this.$store.dispatch('setNavEvent', value)
						break
					case 'navHeaderRightButtonText':
						this.$store.dispatch(
							'setNavHeaderRightButtonText',
							value
						)
						break
					case 'prominent':
						this.$store.dispatch('setNavHeaderProminent', value)
						break
					case 'routeTo':
						this.$store.dispatch('setNavRouteTo', value)
						break
				}
			}
		}
	}
}
