import axios from '../../network/axios'
import {
	SET_BANNER_LIST_ERROR,
	GET_BANNER_LIST,
	SET_BANNER_LIST
} from '../types'

const state = {
	banners: [],
	isFetching: false,
	error: null
}

const actions = {
	getBanners({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_BANNER_LIST)
			axios
				.get('/user/banners')
				.then(({ data }) => {
					if (data.internal_link_query) {
						data.internal_link_query = JSON.parse(
							data.internal_link_query
						)
					}
					commit(SET_BANNER_LIST, data)
					resolve()
				})
				.catch((err) => {
					commit(SET_BANNER_LIST_ERROR, err)
					reject(err)
				})
		})
	}
}

const mutations = {
	[SET_BANNER_LIST_ERROR](state, error) {
		state.error = error
		state.isFetching = false
	},
	[GET_BANNER_LIST](state) {
		state.error = null
		state.isFetching = true
	},
	[SET_BANNER_LIST](state, banners) {
		state.error = null
		state.isFetching = false
		state.banners = banners
	}
}

export default {
	state,
	actions,
	mutations
}
