import { render, staticRenderFns } from "./InHonorOf.vue?vue&type=template&id=7449cd68&scoped=true&"
import script from "./InHonorOf.vue?vue&type=script&lang=js&"
export * from "./InHonorOf.vue?vue&type=script&lang=js&"
import style0 from "./InHonorOf.vue?vue&type=style&index=0&id=7449cd68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7449cd68",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VCol,VExpandTransition,VForm,VRow,VTextarea})
