const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID

export function initFacebookSdk() {
	return new Promise((resolve, reject) => {
		// wait for facebook sdk to initialize before starting the vue app
		window.fbAsyncInit = function () {
			FB.init({
				appId: facebookAppId,
				cookie: true,
				xfbml: true,
				version: 'v8.0'
			})

			// If we ever implement fb login
			// auto authenticate with the api if already logged in with facebook
			// FB.getLoginStatus(({ authResponse }) => {
			// 	if (authResponse) {
			// 		accountService
			// 			.apiAuthenticate(authResponse.accessToken)
			// 			.then(resolve)
			// 	} else {
			// 		resolve()
			// 	}
			// })
			resolve()
		}
		try {
			// load facebook sdk script
			;(function (d, s, id) {
				var js,
					fjs = d.getElementsByTagName(s)[0]
				if (d.getElementById(id)) {
					return
				}
				js = d.createElement(s)
				js.id = id
				js.src = 'https://connect.facebook.net/en_US/sdk.js'
				try {
					// fjs.parentNode.insertBefore(js, fjs)
				} catch {
					throw new Error()
				}
			})(document, 'script', 'facebook-jssdk')
		} catch {
			reject()
			throw new Error('No FB')
		}
	})
}
