<template>
	<div class="story-icon">
		<v-img
			v-if="type === HELPFUL"
			src="@/assets/community/icons/Donor_Count.png"
			style="height: 75px"
			contain
		></v-img>
		<v-img
			v-else-if="type === RUA_NEWS"
			src="@/assets/community/icons/roundup_news.png"
			style="height: 75px"
			contain
		></v-img>
		<v-img
			v-else-if="type === EVENT"
			src="@/assets/community/icons/event.png"
			style="height: 75px"
			contain
		></v-img>
		<v-img
			v-else-if="type === Fact"
			src="@/assets/community/icons/growth.png"
			style="height: 75px"
			contain
		></v-img>
		<v-img
			v-else-if="type === NONPROFIT_NEWS"
			src="@/assets/community/icons/nonprofit_news_icon.png"
			style="height: 75px"
			contain
		></v-img>
		<v-img
			v-else-if="type === ENCOURAGEMENT"
			src="@/assets/community/icons/encouragement.png"
			style="height: 75px"
			contain
		></v-img>
		<v-img
			v-else
			src="@/assets/community/icons/roundup_news.png"
			style="height: 75px"
			contain
		></v-img>
	</div>
</template>

<script>
export default {
	name: 'community-story-img',
	props: ['type'],
	data: () => {
		return {
			HELPFUL: 'helpful',
			RUA_NEWS: 'rua_news',
			NONPROFIT_NEWS: 'nonprofit_news',
			EVENT: 'event',
			Fact: 'fact',
			ENCOURAGEMENT: 'encouragement'
		}
	}
}
</script>

<style lang="scss" scoped>
.story-icon {
	margin: auto;
}
</style>
