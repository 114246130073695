<template>
	<v-container class="nav-footer" fluid>
		<v-row
			class="nav-footer-row"
			:style="{
				paddingBottom: '8px !important'
			}"
		>
			<v-col
				v-for="tab in tabs"
				:key="tab.name"
				:class="{
					active: isActive(tab.name)
				}"
				class="btn nav-footer-col"
				:style="{ paddingTop: iosStyles && '8px !important' }"
				@click="handleClick(tab)"
			>
				<v-badge
					dot
					color="#FF6376"
					overlap
					:value="checkBadges(tab.badge)"
				>
					<v-icon color="#b9bcc6" class="nav-footer-icon">{{
						tab.icon
					}}</v-icon>
				</v-badge>
				<p class="mb-0">
					{{ tab.text }}
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { isIOS } from '@/utilities'

export default {
	name: 'nav-footer',
	props: ['tabs'],
	data: () => ({
		activeTab: null
	}),
	computed: {
		iosStyles() {
			return isIOS()
		}
	},
	methods: {
		...mapActions(['logout']),
		// TO DO - rework this
		isActive(name) {
			return name === this.activeTab
		},
		handleClick(tab) {
			if (tab.name !== 'logout') {
				this.navigate({ name: tab.name }, 'up')
			} else if (tab.name === 'logout') {
				if (confirm('Would you like to log out?')) {
					this.navigate({ name: 'logout' }, 'fade')
				}
			}
		},
		updateActive(to) {
			try {
				const tabNames = this.tabs.map((el) => el.name)
				if (to.name) {
					let rootName = to.name.split('-')[0]
					if (rootName === 'public') {
						rootName = 'public-discover'
					}
					if (tabNames.includes(rootName)) {
						this.activeTab = rootName
					}
				}
			} catch (e) {
				console.log(e)
			}
		},
		checkBadges(list = []) {
			return list.some((el) => this.$store.getters[el])
		}
	},
	watch: {
		$route(to) {
			this.updateActive(to)
		}
	},
	mounted() {
		this.$nextTick(() => {
			const to = this.$router.history.current
			if (to.name) {
				this.updateActive(to)
			}
		})
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/elements.scss';

.nav-footer {
	z-index: 10;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	background: $white;
	max-height: $footer-height !important;

	p {
		font-size: 14px !important;
		color: #747a8d !important;
	}
	.nav-footer-row,
	.nav-footer-col {
		max-height: $footer-height !important;
	}

	.nav-footer-col {
		padding-top: 16px;
	}
	&-icon {
		margin-bottom: 5px;
	}

	.active {
		p,
		i {
			color: $roundup-navy !important;
		}
	}
	.btn {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
</style>
