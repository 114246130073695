<template>
	<v-row justify="center" class="in-honor-of">
		<v-col>
			<v-form ref="form" v-model="valid" @submit.prevent>
				<v-checkbox
					class="mt-0"
					color="#2553cd"
					v-model="in_honor_of"
					style="width: 100%"
					:ripple="false"
				>
					<span slot="label" class="checkbox-label"
						>Make this donation in someone's honor or memory</span
					>
				</v-checkbox>
				<div class="in-honor-of-form-body" v-show="in_honor_of">
					<v-expand-transition>
						<div v-show="in_honor_of">
							<RoundupInput
								class="pt-0 mt-0"
								placeholder="Honoree Full Name - Required"
								v-model="form.honoree_full_name"
								v-on:keydown.enter.prevent
								color="#2553cd"
								:rules="formRules.fullName"
								required
							></RoundupInput>
							<v-checkbox
								color="#2553cd"
								v-model="send_message"
								style="width: 100%; margin-top: 0px"
								:ripple="false"
							>
								<span slot="label" class="checkbox-label"
									>Send Personalized E-mail</span
								>
							</v-checkbox>
						</div>
					</v-expand-transition>
					<v-expand-transition>
						<div v-show="send_message">
							<RoundupInput
								class="pt-0 mt-0"
								placeholder="Recipient Full Name - Required"
								v-on:keydown.enter.prevent
								color="#2553cd"
								:rules="formRules.recipient_full_name"
								v-model="form.recipient_full_name"
							></RoundupInput>
							<RoundupInput
								class="pt-0 mt-0"
								placeholder="Recipient Email - Required"
								v-model="form.recipient_email"
								v-on:keydown.enter.prevent
								color="#2553cd"
								:rules="formRules.email"
							></RoundupInput>
							<v-textarea
								color="#2553cd"
								placeholder="Personal Message - Optional"
								hint="255 character limit"
								:persistent-hint="true"
								no-resize
								v-model="form.personal_message"
								:rules="formRules.personal_message"
								counter
							>
							</v-textarea>
							<v-checkbox
								color="#2553cd"
								v-model="anonymous"
								style="width: 100%"
								:ripple="false"
							>
								<span slot="label" class="checkbox-label"
									>Send anonymously</span
								>
							</v-checkbox>
						</div>
					</v-expand-transition>
				</div>
			</v-form>
		</v-col>
	</v-row>
</template>

<script>
import { emailRules } from '@/utilities/validation'

export default {
	name: 'in-honor-of',
	data() {
		return {
			valid: true,
			in_honor_of: false,
			send_message: false,
			anonymous: false,
			form: {
				honoree_full_name: null,
				recipient_full_name: null,
				recipient_email: null,
				personal_message: null
			},
			emailRules
		}
	},
	methods: {
		onClick() {
			this.in_honor_of = !this.in_honor_of
		}
	},
	computed: {
		formReadyToSubmit() {
			if (!this.in_honor_of) {
				return false
			}

			const firstPartOfForm =
				!!this.in_honor_of && !!this.form.honoree_full_name

			const firstPartOfFormIsValid =
				firstPartOfForm && !this.send_message && this.valid

			if (firstPartOfFormIsValid) {
				return true
			}

			const secondPartOfForm =
				this.send_message &&
				!!this.form.recipient_full_name &&
				!!this.form.recipient_email

			const formIsValid =
				firstPartOfForm && secondPartOfForm && this.valid

			if (formIsValid) {
				return true
			}

			return false
		},
		computedForm() {
			return {
				...this.form,
				iho_anonymous: this.anonymous,
				send_message: this.send_message
			}
		},
		formRules() {
			return {
				recipient_full_name: [
					(v) => {
						if (!this.send_message) {
							return true
						}
						if (!v) {
							return 'Please add a name for the recipient'
						}

						if (v && v.length === 0) {
							return true
						}

						return (
							(v && v.length <= 100) ||
							'Full name must be less than 100 characters'
						)
					}
				],
				fullName: [
					(v) => {
						if (!v) {
							return 'Please add a name'
						}

						if (v && v.length === 0) {
							return true
						}

						return (
							(v && v.length <= 100) ||
							'Full name must be less than 100 characters'
						)
					}
				],
				email: [
					(v) => {
						if (!this.send_message) {
							return true
						}
						return true
					},
					(v) => {
						if (this.send_message) {
							return (
								/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(
									v
								) || 'E-mail must be valid'
							)
						}
						return true
					}
				],
				personal_message: [
					(v) => {
						if (!v) {
							return true
						}

						if (v.length > 255) {
							return 'Cannot exceed 255 characters'
						}

						return true
					}
				]
			}
		}
	},
	watch: {
		in_honor_of: function (v) {
			if (!v) {
				for (var prop in this.form) {
					this.form[prop] = null
				}
				this.anonymous = false
				this.send_message = false
				this.$refs.form.resetValidation()
			} else {
				this.send_message = true
			}
		},
		send_message: function (v) {
			if (!v) {
				for (var prop in this.form) {
					if (prop !== 'honoree_full_name') {
						this.form[prop] = null
					}
				}
				this.anonymous = false
				this.$refs.form.resetValidation()
			}
		},
		formReadyToSubmit: function (v) {
			this.$emit('formValid', v)
		},
		computedForm: {
			handler: function (v) {
				this.$emit('formSubmit', v)
			},
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>
.in-honor-of {
	.checkbox-label {
		width: 100% !important;
		color: $roundup-charcoal !important;
		margin-left: 10px;
		p.error-info {
			background-color: inherit;
			color: #db2137;
			font-size: 0.875rem;
			margin-bottom: 0px !important;
			margin-top: 0px !important;
		}
	}

	&-success {
		p {
			font-style: italic;
		}
	}
}

.checkbox::v-deep .v-input__slot {
	align-items: flex-start !important;
}
</style>
