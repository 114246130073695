export const firstNameRules = [
	(v) => !!v || 'First Name is required',
	(v) =>
		(v && v.length <= 100) || 'First name must be less than 100 characters'
]

export const lastNameRules = [
	(v) => !!v || 'Last Name is required',
	(v) =>
		(v && v.length <= 100) || 'Last name must be less than 100 characters'
]

export const phoneNumberRules = [
	(v) => !!v || 'Phone number is required',
	(v) =>
		/^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(v) ||
		'Phone number must be valid'
]

export const emailRules = [
	(v) => !!v || 'E-mail is required',
	(v) =>
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(
			v
		) || 'E-mail must be valid'
]

export const cardNumberRules = [
	(v) => /^([0-9]{16})$/.test(v) || 'Credit card number must be valid'
]

export const cardExpRules = [
	(v) =>
		/^(0[1-9]|1[0-2])\/?([0-9]{4})$/.test(v) ||
		'Credit card exp must be in valid format - MM/YYYY'
]

export const cardCVCRules = [
	(v) => /^([0-9]{3})$/.test(v) || 'Credit card CVC must be valid'
]

export const cardZipRules = [
	(v) => /^([0-9]{5})$/.test(v) || 'Credit card Zip must be valid'
]

export const passwordRules = [
	(v) => !!v || 'You must enter a password',
	(v) =>
		(v && v.length >= 6) ||
		'Your password must be at least 6 characters long'
]
