<template>
	<v-dialog
		v-model="showOnboarding"
		hide-overlay
		transition="dialog-bottom-transition"
		max-width="480"
		persistent
		content-class="onboarding-dialog"
		style="margin: 0 !important"
		:fullscreen="showFullscreen"
	>
		<v-container class="onboarding-body">
			<v-row class="stepper-row">
				<v-spacer></v-spacer>
				<div
					:class="[`stepper ${currentStep >= i ? 'active' : ''}`]"
					v-for="i in 5"
					:key="i"
				/>
				<v-spacer></v-spacer>
			</v-row>
			<v-row style="margin-top: 32px !important">
				<v-spacer></v-spacer>
				<v-img
					:src="`https://d2gbgm7n6hyv3d.cloudfront.net/assets/app-onboarding/onboarding${currentStep}.png`"
					class="image"
					contain
				/>
				<v-spacer></v-spacer>
			</v-row>
			<h1 class="step-title">{{ stepTitle }}</h1>
			<p class="step-body">{{ stepBody }}</p>
			<v-row style="margin-top: 16px !important" v-if="currentStep < 5">
				<v-spacer></v-spacer>
				<Roundup-Button
					color="white"
					@click="() => currentStep--"
					v-if="currentStep > 1"
					style="margin-right: 16px !important"
					>Back</Roundup-Button
				>
				<Roundup-Button color="white" @click="() => currentStep++"
					>Next</Roundup-Button
				>
				<v-spacer></v-spacer>
			</v-row>
			<v-row
				style="
					margin-top: 16px !important;
					margin-left: 32px !important;
					margin-right: 32px !important;
				"
				v-else
			>
				<v-spacer></v-spacer>
				<div style="width: 80% !important">
					<Roundup-Button @click="finishOnboarding" block
						>Start Giving</Roundup-Button
					>
					<p class="login-text" @click="toLogin">
						Already have an account? Login
					</p>
				</div>
				<v-spacer></v-spacer>
			</v-row>

			<p class="skip-text" @click="finishOnboarding">Skip</p>
		</v-container>
	</v-dialog>
</template>

<script>
import NavHeader from '@/components/Nav/NavHeader'
export default {
	name: 'onboarding-dialog',
	components: {
		NavHeader
	},
	data: () => {
		return {
			currentStep: 1,
			finished: false
		}
	},
	computed: {
		showOnboarding() {
			return (
				localStorage.getItem('has_onboarded') !== 'true' &&
				!this.finished
			)
		},
		stepTitle() {
			switch (this.currentStep) {
				case 1:
					return 'Make donations easily'
				case 2:
					return 'Securely link your cards'
				case 3:
					return 'Spend like normal'
				case 4:
					return 'Make a significant impact'
				case 5:
					return 'Get Started'
			}
		},
		stepBody() {
			switch (this.currentStep) {
				case 1:
					return 'Simplify your giving to various nonprofits through rounding up your purchases to the next dollar.'
				case 2:
					return 'Connect your credit or debit card to the app and securely authorize RoundUps with your bank.'
				case 3:
					return 'The app will automatically track and round up purchases made with your linked accounts.'
				case 4:
					return 'Each month your RoundUps are totaled and donated to your selected nonprofits.'
				case 5:
					return
			}
		},
		showFullscreen() {
			return window.innerWidth < 500
		}
	},
	methods: {
		finishOnboarding() {
			localStorage.setItem('has_onboarded', 'true')
			this.finished = true
			if (window.HubSpotConversations) {
				window.HubSpotConversations.widget.refresh()
			}
		},
		toLogin() {
			this.finishOnboarding()
			this.navigate({ name: 'login' })
		}
	},
	mounted() {
		setTimeout(() => {
			if (window.HubSpotConversations && this.showOnboarding) {
				window.HubSpotConversations.widget.remove()
			}
		}, 500)
	}
}
</script>

<style lang="scss" scoped>
.onboarding-dialog {
	min-height: 100vh !important;
	.onboarding-body {
		background-color: white !important;
		height: 100vh !important;

		.stepper-row {
			margin: 0 !important;
			margin-top: 48px !important;
			.stepper {
				background-color: $roundup-light-grey !important;
				height: 4px !important;
				width: 48px;
				margin-left: 8px !important;
				margin-right: 8px !important;
				border-radius: 2px !important;
				&.active {
					background-color: $roundup-light-slate !important;
				}
			}
		}

		.step-title {
			margin-top: 32px !important;
			margin-left: 16px !important;
			margin-right: 16px !important;
			text-align: center !important;
			font-size: 1.5rem !important;
			color: $roundup-primary !important;
			font-family: $font-stack !important;
		}

		.step-body {
			margin-top: 32px !important;
			padding-left: 15% !important;
			padding-right: 15% !important;
			text-align: center !important;
			font-size: 1.25rem !important;
			color: $roundup-slate !important;
			font-family: $font-stack !important;
			font-weight: $font-regular !important;
		}

		.skip-text {
			position: absolute !important;
			bottom: 48px;
			left: 50%;
			transform: translate(-50%, 0);

			text-align: center !important;
			font-size: 1.25rem !important;
			color: $roundup-light-slate !important;
			font-family: $font-stack !important;
			&:hover {
				cursor: pointer !important;
			}
		}

		.login-text {
			margin-top: 32px !important;
			text-align: center;
			color: $roundup-primary !important;
			text-decoration: underline !important;
			&:hover {
				cursor: pointer !important;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.stepper {
		width: 36px !important;
	}
	.image {
		width: 60%;
		max-height: 200px;
	}
	.skip-text {
		bottom: 24px !important;
	}
}
</style>
