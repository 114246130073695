import axios from '../../network/axios'
import {
	INST_LIST,
	INST_LIST_REQUEST,
	GET_PROJECTED_ROUNDUPS,
	SET_PROJECTED_ROUNDUPS,
	SET_INST_LIST_ERROR,
	ADD_TO_ONE_TIME_LIST
} from '../types'

const state = {
	isFetching: false,
	items: [],
	error: null,
	roundups: []
}

const getters = {
	myInstitutions: (state) => {
		if (state.roundups.length === 0) {
			return state.items
		}
		let combinedItems = [...state.items]
		for (var ui of combinedItems) {
			const ru_ui = state.roundups.find((el) => el.id === ui.id)

			if (ru_ui) {
				ui.projected_amount = ru_ui.amount
			}
		}
		return combinedItems
	},
	myInstUUIDs: (_, getters) => {
		return getters.myInstitutions.map((ui) => ui.institution.uuid)
	},
	roundups: (state) => state.roundups,
	myInstitutionsFetching: (state) => state.isFetching,
	hasRoundUpInstitutions: (state) => {
		return state.items.filter((ui) => ui.roundups_enabled).length > 0
	},
	totalRoundupMinimumDonations: (_, getters) => {
		let total = 0
		getters.myInstitutions.forEach((inst) => {
			if (inst.roundups_enabled && !inst.is_paused) {
				total += parseFloat(inst.minimum_donation)
			}
		})
		return total
	},
	numberOfRoundupInstitutions: (_, getters) => {
		const roundupInstitutions = getters.myInstitutions.filter(
			(inst) => inst.roundups_enabled && !inst.is_paused
		)

		if (roundupInstitutions && roundupInstitutions.length > 0) {
			return roundupInstitutions.length
		} else {
			return 0
		}
	},
	totalMinGreaterThanMax: (_, getters) => {
		if (getters.maxDonation) {
			return getters.totalRoundupMinimumDonations > getters.maxDonation
		}
		return false
	}
}

const actions = {
	getMyInstitutions({ commit }) {
		return new Promise((resolve, reject) => {
			commit(INST_LIST_REQUEST)
			axios
				.get('/user/institutions')
				.then(({ data }) => {
					if (data.length > 0) {
						data = data
							.sort((a, b) =>
								a.institution.name > b.institution.name ? -1 : 1
							)
							.sort((a, b) => (a.roundups_enabled ? 1 : -1))
					}
					commit(INST_LIST, data)
					resolve()
				})
				.catch(() => reject())
		})
	},
	addInstToOnetimeGift({ commit, dispatch }, inst) {
		dispatch('getInstitution', inst.uuid)
			.then((i) => {
				commit(ADD_TO_ONE_TIME_LIST, i)
			})
			.catch((e) => console.log(e))
	}
}

const mutations = {
	[INST_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[INST_LIST](state, institutions) {
		state.isFetching = false
		state.items = institutions
	},
	[GET_PROJECTED_ROUNDUPS](state) {
		state.isFetching = true
	},
	[SET_PROJECTED_ROUNDUPS](state, roundups) {
		state.roundups = roundups
	},
	[SET_INST_LIST_ERROR](state, error) {
		state.error = error
	},
	[ADD_TO_ONE_TIME_LIST](state, inst) {
		let items = [...state.items]
		let hasInst =
			items.filter((i) => {
				return i.institution.uuid === inst.uuid
			}).length > 0
		if (hasInst) {
			return
		}

		items.push({ institution: inst })
		state.items = items
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
